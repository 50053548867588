import { ContentfulBasicComponent } from '@3as-affiliates/shared/types-configs';
import {
  breakpoints,
  colors,
  Link,
  LinkProps,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';
import { contentfulSpacing, grid, section } from '../functional-css';
import { ResponsiveImage } from '../responsive-image/ResponsiveImage';

export const ImageTextStacked = styled(ImageTextStackedComponent)`
  ${(fields) => contentfulSpacing(fields)}
  ${grid};
  grid-row-gap: 2.5rem;
`;

const Item = styled.div`
  grid-column: 2 / span 4;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${breakpoints.md} {
    grid-column: span 4 / span 4;
  }
`;

const Subheading = styled(Text)`
  p {
    margin: 0;
    white-space: break-spaces;
  }
`;

const Image = styled(ResponsiveImage)`
  display: block;
  img {
    max-width: 100%;
  }
`;

export const ImageTextStackedFlexCenter = styled(ImageTextStacked)`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    flex: 1;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const ImageTextStackedHorizontal = styled(
  ImageTextStackedComponentHorizontal
)`
  ${(fields) => contentfulSpacing(fields)}
  ${Item} {
    ${section};
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 1rem;

    ${breakpoints.md} {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    ${breakpoints.md2} {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 3.12rem;
    }
  }
  ${Image} {
    grid-column: span 6 / span 6;

    ${breakpoints.md} {
      grid-column: span 12 / span 12;
    }

    ${breakpoints.md2} {
      grid-column: span 1 / span 1;
    }
  }
  ${TextContainer} {
    grid-column: span 6 / span 6;
    ${breakpoints.md} {
      grid-column: span 8 / span 8;
      text-align: left;
    }
    ${breakpoints.md2} {
      grid-column: span 1 / span 1;
      grid-column-start: 0;
    }
  }
`;

export const ImageTextStackedGrey = styled(ImageTextStacked)`
  background-color: ${colors.neutralBrightest};
`;

export const ImageTextStackedFullReview = styled(ImageTextStacked)`
  ${Item} {
    flex-direction: row;
    text-align: left;
  }

  ${Image} {
    flex-shrink: 0;
    width: 72px;
  }
`;

type TypeEntity = ContentfulBasicComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

function ImageTextStackedComponent({
  className = '',
  ...fields
}: ComponentProps) {
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      {fields.content &&
        fields.content.map((entry) => {
          if (!entry) return null;

          return (
            <Item key={entry.fields.headline}>
              {entry.fields.image && <Image {...entry.fields.image.fields} />}
              {entry.fields.headline && (
                <Text
                  as={entry.fields.headingLevel}
                  size={5}
                  weight={'feather'}
                >
                  {entry.fields.headline}
                </Text>
              )}
              {entry.fields.subheading && (
                <Subheading forwardedAs={'span'} size={3} weight={'feather'}>
                  <Markdown>{entry.fields.subheading}</Markdown>
                </Subheading>
              )}
              {entry.fields.cta && entry.fields.cta?.[0] && (
                <Link
                  href={entry.fields.cta?.[0].fields.url}
                  variant={'button'}
                  btnVariantProps={
                    { btnSize: 'large' } as LinkProps['btnVariantProps']
                  }
                >
                  {entry.fields.cta?.[0].fields.text}
                </Link>
              )}
            </Item>
          );
        })}
    </div>
  );
}

function ImageTextStackedComponentHorizontal({
  className = '',
  ...fields
}: ComponentProps) {
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      {fields.content &&
        fields.content.map((entry) => {
          if (!entry) return null;

          return (
            <Item key={entry.fields.headline}>
              {entry.fields.image && <Image {...entry.fields.image.fields} />}
              <TextContainer>
                {entry.fields.headline && (
                  <Text
                    as={entry.fields.headingLevel}
                    size={5}
                    weight={'feather'}
                  >
                    {entry.fields.headline}
                  </Text>
                )}
                {entry.fields.subheading && (
                  <Subheading forwardedAs={'span'} size={3} weight={'feather'}>
                    <Markdown>{entry.fields.subheading}</Markdown>
                  </Subheading>
                )}
                {entry.fields.cta && entry.fields.cta?.[0] && (
                  <Link
                    href={entry.fields.cta?.[0].fields.url}
                    variant={'button'}
                    btnVariantProps={
                      {
                        btnSize: 'medium',
                        btnVariant: 'outline',
                      } as LinkProps['btnVariantProps']
                    }
                  >
                    {entry.fields.cta?.[0].fields.text}
                  </Link>
                )}
              </TextContainer>
            </Item>
          );
        })}
    </div>
  );
}
