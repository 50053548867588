import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';

import { RewardsGift } from '../assets';
import { ShareLink } from '../share-link';

const GiftsTitle = styled(Text)`
  margin: 12px 0;
`;

const GiftsSubtitle = styled(Text)`
  margin: 12px 0;
`;

const GiftLinkContainer = styled.div`
  display: flex;
  position: relative;
  top: 6px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: #f0f0f0;
`;

const GiftLinkWrapper = styled.div`
  position: relative;
  top: 6px;
`;

const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

interface GiftsProps {
  title?: string;
  subtitle?: string;
  description?: string;
  shareLinkTitle?: string;
  shareLinkUrl: string;
  icon?: string;
}

export const Gifts = ({
  title = 'Gifts',
  subtitle = 'One time gifts sold only as an annual plan.',
  description = '**This link should only be used for realtors or property sellers. Gifting is still in pilot.',
  shareLinkTitle = 'YOUR GIFT LINK',
  shareLinkUrl,
  icon = RewardsGift,
}: GiftsProps) => {
  return (
    <>
      <GiftsTitle forwardedAs={'h2'} size={5} weight={'feather'}>
        {title}
      </GiftsTitle>
      <GiftsSubtitle forwardedAs={'p'} size={3} weight={'feather'}>
        {subtitle}
      </GiftsSubtitle>
      <GiftsSubtitle forwardedAs={'p'} size={2} weight={'heavy'}>
        {description}
      </GiftsSubtitle>
      <GiftLinkContainer>
        <GiftLinkWrapper>
          <ShareLink
            title={shareLinkTitle}
            link={shareLinkUrl}
            url={shareLinkUrl}
          />
        </GiftLinkWrapper>
        <Icon src={icon} alt={'gift'} />
      </GiftLinkContainer>
    </>
  );
};
