// until we decide on a content config platform like contentful, we will use this file to store the content for the comparison
import {
  PitchAssistConfig,
  TypeProductContentConfig,
} from '@3as-affiliates/partners/web/components';
import bannerImg from '../../assets/comparion/banner-appliance-protect.png';
import tabImg from '../../assets/comparion/tab-appliance-protect.png';

const pitchAssist: PitchAssistConfig = {
  title: 'Asurion Appliance Protect pitch assist',
  collection: [
    {
      settings: {
        header: 'Offers',
        iconSrc: 'ChatBubblesCircle',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Remind your customers that common breakdowns may not be covered under their homeowners insurance.</strong>
            <ul>
              <li>Did you know that things like your fridge, washer, and dryer may not be covered from breakdowns in your homeowner’s coverage?</li>
            </ul>
          `,
        },
        {
          text: `
            <strong>Reiterate the value of the customer’s appliance investment.</strong>
            <ul>
              <li>The average American home has $10,000 worth of appliances* in their home. What’s your backup plan if they break of malfunction?</li>
            </ul>
          `,
        },
        {
          text: `
              <strong>Showcase the value of Appliance Protect to close the sale!</strong>
              <ul>
                <li>
                  Get total home protection and bundle in coverage to protect the appliances you rely on everyday. No matter where you bought it – if it breaks, we’ll fix it. You’ll get access to highly trained pros to fix it fast – and if we can’t fix it, we’ll help you replace it. And, you’ll get an unlimited number of claims with no impact to your homeowner’s premium—all for about $1/day. Would you like to add this coverage on?
                </li>
              </ul>
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Rebuttals',
        iconSrc: 'Refresh',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Cost --</strong> I understand cost being a concern- with Appliance Protect, you can potentially save money in the future. When an eligible device experiences a covered breakdown, we will attempt to repair it, and if it can’t be repaired, we will help you replace it. You’ll never pay more than $99 per claim! This would add an additional protection for your eligible major appliances, and never impact your home insurance premium.
          `,
        },
        {
          text: `
            <strong>Already covered –-</strong> You’re correct that homeowners insurance does provide coverage for a wide range of potential home issues. However, it’s essential to understand that standard home insurance typically covers damage to appliances due to a covered peril such as fire, hail, or a similar incident. On the other hand, Appliance Protect is specifically designed to cover the repair or replacement costs when your appliances break down due to wear and tear, mechanical failure, or electrical breakdown—situations that are typically not covered by a standard homeowners insurance policy. It’s a cost-effective way to avoid unexpected out-of-pocket expenses and ensure you have peace of mind.
          `,
        },
        {
          text: `
           <strong>Overwhelmed by decisions --</strong> It’s completely understandable to feel overwhelmed by all the decisions that come with buying a home. As a trusted partner, I want to make this process is easy and stress-free. Appliance Protect is easy to use and offers peace of mind if unexpected breakdowns occur.
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Product Details',
        iconSrc: 'IconPlaceholder',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <h3>$34.99 Per month + Taxes</h3>
          `,
        },
        {
          text: `
            <strong>Appliance Covered:</strong>
            <ul>
              <li>Ovens</li>
              <li>Built-in Microwaves, excluding countertop</li>
              <li>Washer & Dryers</li>
              <li>Refrigerators</li>
              <li>Dishwashers</li>
              <li>Freezers</li>
              <li>Ranges and cooktops</li>
              <li>Built-in food centers</li>
              <li>Freestanding Ice Makers</li>
              <li>Trash Compactors</li>
            </ul>
          `,
        },
        {
          text: `
           <strong>Does not cover: </strong>
           <p>HVAC, Water heaters, Water Tanks</p>

           <p>For a full list of coverage please see the program Terms and Conditions.</p>
          `,
        },
        {
          text: `
            <strong>Additional details</strong>
            <ul>
              <li>The plan offers appliance troubleshooting on Day 1. Other coverage starts on Day 31. Pre-existing conditions or breakdowns before Day 31 are not covered.</li>
              <li>A service fee of up to $99 plus tax applies for each approved claim.</li>
              <li>Up to $1,000 homeowners claim benefit if your covered product experiences a breakdown which subsequently causes damage to other products or fixtures at your home. (One claim per year max)</li>
              <li>$100 Dry cleaning reimbursement (per claim)</li>
              <li>$300 Food Reimbursement – (per claim)</li>
              <li>The plan provides up to $10,000 in coverage every 12 months and up to $10,000 per covered product per approved claim.</li>
            </ul>
            <p>Any preexisting conditions or breaks that occur before day 31 are not covered.</p>
          `,
        },
      ],
    },
  ],
};

export const viuApplianceProtectProductContent: TypeProductContentConfig = {
  name: 'Appliance Protect',
  d2cUri: '/affiliate/viu/appliance-protect/personal-info',
  tabImage: { url: tabImg, alt: 'Appliance Protect' },
  bannerImage: { url: bannerImg, alt: 'A collection of electronic devices.' },
  pitchAssist,
  // featuresConfig,
  // trainingMaterialsConfig
};
