import { Routes } from '@3as-affiliates/partners/web/types-configs';
import {
  AffiliationType,
  OrganizationInvertedKeys,
  SupportedPartners,
} from '@3as-affiliates/shared/types-configs';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
`;

interface Props {
  children: ReactNode;
  pageName: string;
  showUserAvatar?: boolean;
}

export const PageContainerWithViewAnalyticsHeaderFooter = ({
  children,
  pageName,
  showUserAvatar = true,
}: Props) => {
  const { affiliateInfo } = useAppData();
  const affiliations = affiliateInfo?.affiliations as
    | AffiliationType[]
    | undefined;
  const affiliation = affiliations?.at(0);
  const orgId = affiliation?.organizationId || '';
  const partner = OrganizationInvertedKeys[orgId] || '';

  const termsOfUseUrl =
    partner === SupportedPartners.VIU
      ? `https://www.asurion.com/legal/viu`
      : '/terms-conditions';
  const privacyPolicyUrl = `/privacy-notice`;

  const navigate = useNavigate();
  const { dispatchClickAnalytics } = useClickAnalytics();

  const onProfileClick = () => {
    dispatchClickAnalytics({
      Cta: 'Header Profile Menu Item Click button',
      ActionId: 'ProfileMenuItem',
    });
  };

  const onProvideFeedbackClick = () => {
    dispatchClickAnalytics({
      Cta: 'Header Report Bug Item Click button',
      ActionId: 'ProvideFeedbackMenuItem',
    });

    navigate(Routes.LoggedInProvideFeedback);
  };

  return (
    <PageContainerWithViewAnalytics pageName={pageName}>
      <Header
        pageName={pageName}
        showUserAvatar={showUserAvatar}
        onProfileClick={onProfileClick}
        onProvideFeedbackClick={onProvideFeedbackClick}
      />
      <Container>{children}</Container>
      <Footer
        termsOfUseUrl={termsOfUseUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
    </PageContainerWithViewAnalytics>
  );
};
