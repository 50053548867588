export enum Routes {
  AuthRedirectCallback = '/redirect',
  LoggedInHome = '/home',
  LoggedInDecline = '/home/decline',
  LoggedInProvideFeedback = '/home/feedback',
  LoggedOutLanding = '/landing',
  TermsConditions = '/terms-conditions',
  TermsConditionsPartners = '/terms-conditions/:product/:partner',
  PrivacyNotice = '/privacy-notice',
  Unauthorized = '/unauthorized',
  Enrollment = '/enrollment/:product/:partner/:aID',
  AgentEnrollmentNoAuth = '/agent-enrollment/:partner/:aID',
  AgentEnrollmentNoAuthWithProduct = '/agent-enrollment/:product/:partner/:aID',
  DeprecatedEnrollmentURLHomePlusComparion = '/enrollment/:aID',
  SalesProduct = 'sales/product/:productSlug',
  SalesProductDecline = 'sales/decline/:productSlug',
}

// export const defaultLoggedInRoute = Routes.LoggedInHome;

export const defaultLoggedOutRoute = Routes.LoggedOutLanding;
