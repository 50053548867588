import { ContentfulVerticalSpacingComponent } from '@3as-affiliates/shared/types-configs';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { px } from '../functional-css';

type VerticalSpacingProps = ContentfulVerticalSpacingComponent['fields'];

export const VerticalSpacing = styled.div<VerticalSpacingProps>`
  width: 100%;
  margin-top: ${(fields) => px(fields?.defaultPx)};

  ${breakpoints.md} {
    margin-top: ${(fields) => px(fields?.mdPx)};
  }

  ${breakpoints.md2} {
    margin-top: ${(fields) => px(fields?.md2Px)};
  }

  ${breakpoints.lg} {
    margin-top: ${(fields) => px(fields?.lgPx)};
  }

  ${breakpoints.xl} {
    margin-top: ${(fields) => px(fields?.xlPx)};
  }

  ${breakpoints.xxl} {
    margin-top: ${(fields) => px(fields?.xxlPx)};
  }
`;
