// until we decide on a content config platform like contentful, we will use this file to store the content for the comparison
import {
  PitchAssistConfig,
  TypeProductContentConfig,
} from '@3as-affiliates/partners/web/components';
import bannerImg from '../../assets/comparion/banner-home-plus.png';
import tabImg from '../../assets/comparion/tab-home-plus.png';

const pitchAssist: PitchAssistConfig = {
  title: 'Pitch Assist',
  collection: [
    {
      settings: {
        header: 'Offer Pitches',
        iconSrc: 'ChatBubblesCircle',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>
              Option 1 – As you are discussing Homeowner’s policies, ask questions about their home tech!
            </strong>
            <ul>
            <li>
              "Your new home sounds great, Congrats! Will you be picking up any TVs, (Home tech) for the new place?"
            </li>
            <li>
              "Are you planning to add a new PC or PS5 in the home?"
            </li>
            <li>
              "Have you thought about home security features you will be adding to the home, or would like to add?"
            </li>
            </ul>
          `,
        },
        {
          text: `
            <strong>
              Option 2 – Position Asurion Home+ using the information you learned earlier –
              gather those breadcrumbs the customers shared!
            </strong>
            <ul>
            <li>
              "Now that we have protected your new home or Car with Insurance, have you thought about
               protecting the home tech (add in specifics, tv, gaming, laptops etc.) you mentioned earlier?"
            </li>
            <li>
              "Asurion offers In Home installations on Eligible Devices - for just $49 per device we can
              get your new home connected! Asurion can help get your ring doorbell installed, Smart Home
              Security & Thermostats and even mount your TV!"
            </li>
            </ul>
          `,
        },
        {
          text: `
            <strong>
              Option 3 – 1. Showcase the value of Home+ to close the sale!
            </strong>
            <ul>
            <li>
              "Asurion Home+ covers thousands of eligible Home tech! Whether they are new, or you have
              had them for years, no receipts required! Asurion Home+ will repair, replace or reimburse your
              devices (($,0, $49, $99 services fee*), without impacting to your homeowner’s policy!
              Our top three most replaced devices are TVs, laptops, and game consoles. Let’s add this on for you,
              and ensure you are protected."
            </li>
            </ul>
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Product Details',
        iconSrc: 'Calendar',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Protection / Tech Support</strong> – Asurion Home+ provides 24/7 technical support and product
            protection for a variety of common home devices including, but not limited to, TVs,
            Laptops, Tablets, Printers, Gaming Systems, Wearables (smartwatches), Air Pods, etc.,
            all for only $24.99 per month (plus taxes)!
            <ul>
              <li>
                Even includes coverage for accidental damage from handling for select portable devices,
                including your eligible Gaming systems, Tablets, Laptops, and Wearables (smartwatches).
              </li>
            </ul>
          `,
        },
        {
          text: `
            <strong>UBIF Services</strong> - Once enrolled, Asurion Home+ customers instantly gain access
            to three complementary in-store services at our Asurion/UBreakifix Stores.
            A PC tune-up, Backup and Transfer content and Virus removal services
            for a laptop (MAC or PC).
          `,
        },
        {
          text: `
            <strong>Home Installs</strong> - Asurion Home+ also offers In Home installations on Eligible Devices - for just
            $49 per device! We can help get your ring doorbell installed, Smart Thermostat and even mount
            your new brand-new TV! Once enrolled you can schedule your visit with our team.
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Rebuttals to common objections',
        iconSrc: 'Refresh',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Option 1</strong> – I understand cost being a concern, by starting your enrollment today,
            you can potentially save money in the future. When an eligible device suffers a
            covered breakdown, Asurion will attempt to repair it, and if it cannot be repaired,
            Asurion will look to replace it! And the highest service fee you’ll ever have to pay is $99!
            This would add an extra layer of protection for your everyday electronics,
            while never affecting your home insurance premiums or deductibles.
          `,
        },
        {
          text: `
            <strong>Option 2</strong> – I understand cost being a concern, by starting your enrollment today,
            you can potentially save money in the future. When an eligible device suffers
            a covered breakdown or you are on the move and need a new TV or Ring Camera installed,
            Asurion will be there to protect you, and the highest service fee you’ll ever have to pay
            is $99 and installation fee $49! Not only protecting your devices but setting up your home,
            while never affecting your home insurance premiums or deductibles.
          `,
        },
        {
          text: `
            <strong>Option 3</strong> - On top of that you get 24/7 Tech Support. This means Experts are just a tap
            or call away to help answer questions or give step by step instructions anytime
            you are ready, as you know moving never ends not even at 2am when you are eager
            to get your new home set up! It also includes Protection, Support and Asurion
            in store services to maximize your device protection. All these amazing benefits
            of the planv, while never affecting your home insurance premiums or deductibles.
          `,
        },
      ],
    },
  ],
};

export const viuHomePlusProductContent: TypeProductContentConfig = {
  name: 'Home+',
  d2cUri: '/affiliate/viu/home-plus/personal-info',
  tabImage: { url: tabImg, alt: 'Asurion Home+' },
  bannerImage: { url: bannerImg, alt: 'A collection of electronic devices.' },
  pitchAssist,
  // featuresConfig,
  // trainingMaterialsConfig
};
