import { SupportedPartners } from '@3as-affiliates/shared/types-configs';
import HelloMessage from '../assets/hello-message.svg';
import LightBulbIdea from '../assets/light-bulb-idea.svg';
import ProtectionEnrollments from '../assets/protection-enrollments.svg';

export const featuresConfig = [
  {
    title: 'Guided customer enrollment',
    subtitle:
      'A step-by-step process to enroll your customers in Asurion products',
    image: {
      src: ProtectionEnrollments,
      alt: 'protection',
    },
  },
  {
    title: 'Product education',
    subtitle:
      'Everything you need to know from expert-created product training to subscription details',
    image: {
      src: LightBulbIdea,
      alt: 'idea',
    },
  },
  {
    title: 'Starter scripts',
    subtitle:
      'Pre-written pitch and rebuttal content customized to the product you’re selling',
    image: {
      src: HelloMessage,
      alt: 'hello',
    },
  },
];

export const trainingMaterialsConfig = [
  {
    company: SupportedPartners.Comparion,
    content: [
      {
        label: 'The Asurion Home+ Advantage',
        // TODO: move to config at later stage
        url: 'https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=3d4455cd-f246-4403-aa53-8218e48a5fa2',
      },
      {
        label: 'Comparion Home+ Enrollment',
        // TODO: move to config at later stage
        url: 'https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=0ed20bd8-8f97-4502-a3c8-db9ca143f37b',
      },
      {
        label: 'Comparion Remarketing Enrollment',
        // TODO: move to config at later stage
        url: 'https://app.cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=ee2fc395-92ef-46dc-8c78-101d2e1fc293',
      },
    ],
  },
];
