export type HttpErrorOptions = {
  expose?: boolean;
};

export class HttpError extends Error {
  private expose: boolean;

  constructor(
    public readonly statusCode: number,
    public override readonly message: string,
    options?: HttpErrorOptions
  ) {
    super(message);

    this.expose = options?.expose || false;
  }
}
