import {
  Intro,
  TypeIntroComponentPassthroughFields,
} from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import styled from 'styled-components';

const Layout = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
`;

const passthroughFields: TypeIntroComponentPassthroughFields = {
  content: [
    {
      fields: {
        cmsDescription: '',
        headingLevel: 'h1',
        headline: '404 Page Not Found.',
      },
    },
  ],
};

type NotFoundProps = {
  pageName: string;
};
export const NotFound = ({ pageName }: NotFoundProps) => {
  return (
    <PageContainerWithViewAnalytics
      pageName={`${PageNames.NotFound} | ${pageName}`}
    >
      <Header pageName={PageNames.NotFound} />
      <Layout>
        <Intro {...passthroughFields} />
      </Layout>
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
