import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const TermsOfUseAnchor = styled.div`
  color: ${colors.black};
  margin-right: 4px;
  margin-left: 4px;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
`;

type Props = {
  customClickHandler?: (url: string) => void;
  termsOfUseUrl?: string;
};

const termsOfUseUrlDefault = 'https://www.asurion.com/terms-conditions';

export const TermsOfUse = ({ customClickHandler, termsOfUseUrl }: Props) => {
  const url = termsOfUseUrl || termsOfUseUrlDefault;

  const handleClick = () => {
    if (customClickHandler) {
      customClickHandler(url);
      return;
    }

    window.open(url, '_blank');
  };

  return (
    <TermsOfUseAnchor
      data-testid="footer-terms-of-use-link"
      aria-describedby="new-window-warning"
      onClick={handleClick}
      role="link"
    >
      Terms of Use
    </TermsOfUseAnchor>
  );
};
