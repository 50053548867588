import { Size } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

export const PageContainer = styled.div`
  width: ${Size.XL}px;
  max-width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  margin: 0 auto;
`;
