import { ContentfulLogosComponent } from '@3as-affiliates/shared/types-configs';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { contentfulSpacing, grid } from '../functional-css';
import { ResponsiveImage } from '../responsive-image/ResponsiveImage';

export const LogosLargeFlexCenter = styled(LogosLargeComponent)`
  ${(fields) => contentfulSpacing(fields)}
  ${grid};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 1rem;

  ${breakpoints.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    > div {
      flex: 1;
    }
  }
`;
const Item = styled.div`
  text-align: center;
`;
const Image = styled(ResponsiveImage)`
  display: block;
  img {
    max-width: 100%;
  }
`;

type TypeEntity = ContentfulLogosComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

function LogosLargeComponent({ className = '', ...fields }: ComponentProps) {
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      {fields.images.map((image) => {
        if (!image) return null;
        return (
          <Item key={image.fields.label}>
            <Image {...image.fields} />
            <Text>{image.fields.label}</Text>
          </Item>
        );
      })}
    </div>
  );
}
