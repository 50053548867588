import {
  Accordion,
  AccordionSection,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { PitchAssistConfig } from './types';

const PitchAssistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 660px;
  max-width: 100%;
`;

const StyledText = styled.h2`
  color: ${colors.black};
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 12px 0;
`;

const StyledBlock = styled.div`
  padding-left: 0;
  padding-top: 1rem;
  margin: 0;
`;

const StyledBlockItem = styled.div`
  margin-bottom: 1rem;
`;

interface PitchAssistProps {
  config: PitchAssistConfig | null;
}

export const PitchAssist = ({ config }: PitchAssistProps) => {
  return (
    config && (
      <PitchAssistWrapper>
        <StyledText>{config.title}</StyledText>
        <Accordion>
          {config.collection.map((item, index) => {
            return (
              <AccordionSection
                key={index}
                header={item.settings.header}
                iconSrc={item.settings.iconSrc}
                transitionDuration={item.settings.transitionDuration}
                transitionTimingFunction={
                  item.settings.transitionTimingFunction
                }
                transitionMaxHeight={'300vh'}
              >
                <StyledBlock>
                  {item.listItems.map((listItem, itemIndex) => (
                    <StyledBlockItem key={itemIndex}>
                      {parse(listItem.text)}
                    </StyledBlockItem>
                  ))}
                </StyledBlock>
              </AccordionSection>
            );
          })}
        </Accordion>
      </PitchAssistWrapper>
    )
  );
};
