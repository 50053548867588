import { fetchHttpClient } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useCallback } from 'react';

const baseUrl = process.env['NX_AFFILIATE_SERVICES_BASE_URL'];

interface AffiliationValidity {
  valid: boolean;
}

export const useAffiliationApi = () => {
  const verifyAffiliationExternalId = useCallback(
    async (
      partner: string,
      externalId: string
    ): Promise<AffiliationValidity> => {
      try {
        const { body } = await fetchHttpClient.get(
          `${baseUrl}/affiliations/${partner}/verify/${externalId}`
        );

        return JSON.parse(body as string) as AffiliationValidity;
      } catch (err) {
        console.error('Error verifyAffiliationExternalId', err);
        return { valid: false };
      }
    },
    []
  );

  return { verifyAffiliationExternalId };
};
