import { Partner } from '@3as-affiliates/shared/types-configs';
import { useAffiliationApi } from '@3as-affiliates/shared/web/data-access/api-client';
import { Skeleton } from '@soluto-private/mx-asurion-ui-react-v3';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotFound } from '../NotFound';

enum ValidState {
  Valid,
  Invalid,
  NotValidated,
}

interface ValidateRouteParamsProps {
  children: ReactNode;
  asurionRerouteEnabled?: boolean;
  pageName: string;
}

// --- Environment Variables --- //
const skipValidateAgentId =
  process.env['NX_REMARKETING_PAGE_SKIP_VALIDATE_AGENT_ID'] === 'true';

export function ValidateRouteParamsPartnerAndAID({
  children,
  asurionRerouteEnabled = false,
  pageName,
}: ValidateRouteParamsProps) {
  const { product, partner, aID } = useParams();
  const { verifyAffiliationExternalId } = useAffiliationApi();

  let backupProductRoute = '';
  switch (product) {
    case 'appliance-protect': {
      backupProductRoute = 'applianceplus';
      break;
    }
    case 'home-plus': {
      backupProductRoute = 'homeplus';
      break;
    }
    case 'appliance-plus': {
      backupProductRoute = 'applianceplus';
      break;
    }
    default: {
      backupProductRoute = '';
      break;
    }
  }

  const [validState, setValidState] = useState(ValidState.NotValidated);

  useEffect(() => {
    const hasAID = aID !== undefined;

    if (!hasAID) {
      setValidState(ValidState.Invalid);
      return;
    }

    if (skipValidateAgentId) {
      // Set valid state is skipping agentId validation is allowed
      setValidState(ValidState.Valid);
    } else {
      // Verify affiliation agentId (externalId) otherwise
      verifyAffiliationExternalId(partner as string, aID as string)
        .then(({ valid }) => {
          setValidState(valid ? ValidState.Valid : ValidState.Invalid);
        })
        .catch((err) => {
          console.error('Error verifying agent id', err);
          setValidState(ValidState.Invalid);
        });
    }
  }, [aID, partner, verifyAffiliationExternalId]);

  useEffect(() => {
    if (validState === ValidState.Invalid && asurionRerouteEnabled) {
      window.location.href = `https://www.asurion.com/${backupProductRoute}`;
    }
  }, [validState, backupProductRoute, asurionRerouteEnabled]);

  return (
    <>
      {validState === ValidState.NotValidated && (
        <Skeleton height={'2.25rem'} />
      )}
      {validState === ValidState.Valid && children}
      {validState === ValidState.Invalid && <NotFound pageName={pageName} />}
    </>
  );
}

export function ValidateRouteParamsAID({
  children,
  pageName,
}: ValidateRouteParamsProps) {
  const { aID } = useParams();
  const { verifyAffiliationExternalId } = useAffiliationApi();

  const [validState, setValidState] = useState(ValidState.NotValidated);

  useEffect(() => {
    const hasAID = aID !== undefined;

    if (!hasAID) {
      setValidState(ValidState.Invalid);
      return;
    }

    if (!skipValidateAgentId) {
      verifyAffiliationExternalId(Partner.COMPARION, aID as string)
        .then(({ valid }) => {
          setValidState(valid ? ValidState.Valid : ValidState.Invalid);
        })
        .catch((err) => {
          console.error('Error verifying agent id', err);
          setValidState(ValidState.Invalid);
        });
    } else {
      setValidState(ValidState.Valid);
    }
  }, [aID, verifyAffiliationExternalId]);

  useEffect(() => {
    if (validState === ValidState.Invalid) {
      window.location.href = `https://www.asurion.com/homeplus`;
    }
  }, [validState]);

  return (
    <>
      {validState === ValidState.NotValidated && (
        <Skeleton height={'2.25rem'} />
      )}
      {validState === ValidState.Valid && children}
      {validState === ValidState.Invalid && <NotFound pageName={pageName} />}
    </>
  );
}
