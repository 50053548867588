import { PageContainer } from '@3as-affiliates/shared/web/components';
import {
  colors,
  DynamicGrid,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SalesProductPageContainer = styled.div`
  ${PageContainer} > div {
    max-width: 800px;
    width: 100%;
  }
`;

export const BannerContainer = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

export const Banner = styled.div`
  background-color: ${colors.white};
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;
  transform: translateY(0%);
  opacity: 1;
  top: 0;

  &.inactive {
    transform: translateY(-100%);
    opacity: 0;
    position: absolute;
  }
`;
export const BannerImage = styled.img`
  max-width: 100%;
`;
export const WelcomeMessage = styled(Text)`
  margin-bottom: 24px;
`;

export const TabbedContentContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.neutralBright};
`;
export const TabGroup = styled.div`
  height: 64px;
  display: flex;
`;
export const Tab = styled(Link)`
  display: block;
  flex: 1 1 auto;
  text-align: center;

  &.active {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${colors.white};
  }

  &.inactive {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${colors.neutralBrightest};
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0;
  }
`;
export const TabImage = styled.img`
  height: 100%;
  width: auto;
`;
export const TabContent = styled.div`
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 768px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const ShareLinkContainer = styled.div``;

export const ProductPitchActionStart = styled.a`
  background: linear-gradient(285.3deg, #6700b8 0%, #a90ad3 100%);
  color: ${colors.white};
`;
export const ProductPitchActionDecline = styled(Link)`
  border: 1px solid ${colors.neutralBright};
  color: ${colors.black};
`;

export const SingleActionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;

  ${ProductPitchActionStart} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
  }
`;

export const ProductPitchActionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 1rem;

  ${ProductPitchActionStart}, ${ProductPitchActionDecline} {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
  }
`;

export const ProductPitchAssistContainer = styled.div``;
export const GiftsContainer = styled.div``;
export const TrainingEducationContainer = styled.div``;
export const TrainingEducationTitle = styled(Text)`
  margin: 12px 0;
`;
export const TrainingEducationGrid = styled(DynamicGrid)``;
