import {
  ContentfulMarketingPage,
  isTypeMarketingPage,
  TypeMarketingPageSkeleton,
} from '@3as-affiliates/shared/types-configs';
import * as contentful from 'contentful';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

type TypeContentfulContext = {
  useMarketingPage: (props: EntryQueryProps) => {
    entry: ContentfulMarketingPage;
    CFQueryContainer: (props: CFQueryContainerProps) => JSX.Element | null;
  };
};

type EntryQueryProps = {
  entryId?: string;
  query?: contentful.EntryQueries<'WITHOUT_UNRESOLVABLE_LINKS'>;
};

type ContentfulProviderProps = {
  children: ReactNode;
};

type CFQueryContainerProps = {
  children: ReactNode;
  error: JSX.Element;
  loading: JSX.Element;
};

const initialContext: TypeContentfulContext = {
  useMarketingPage: () => ({
    entry: undefined,
    CFQueryContainer: () => null,
  }),
};

export const ContentfulContext =
  createContext<TypeContentfulContext>(initialContext);

export function ContentfulProvider({ children }: ContentfulProviderProps) {
  const query = useMemo(
    () => new URLSearchParams(window?.location?.search),
    []
  );
  const previewAccessToken = query.get('contentful_token');

  const space = process.env['NX_CONTENTFUL_SPACE'] as string;
  const accessToken =
    previewAccessToken || (process.env['NX_CONTENTFUL_ACCESS_TOKEN'] as string);
  const host = previewAccessToken
    ? 'preview.contentful.com'
    : 'cdn.contentful.com';

  const client = useMemo(
    () => contentful.createClient({ accessToken, space, host }),
    [accessToken, space, host]
  );

  function generateCFQueryContainer(hasLoaded: boolean, hasError: boolean) {
    return function ({ children, error, loading }: CFQueryContainerProps) {
      return (
        <>
          {hasLoaded && !hasError && children}
          {!hasLoaded && !hasError && loading}
          {hasError && error}
        </>
      );
    };
  }

  function useMarketingPage({ entryId, query = {} }: EntryQueryProps) {
    const [entry, setEntry] = useState<ContentfulMarketingPage>(undefined);
    const [error, setError] = useState(false);
    const hasLoaded = !!entry;

    useEffect(() => {
      (async () => {
        if (!entryId) return;

        try {
          const entry = await client.withoutUnresolvableLinks.getEntry<
            TypeMarketingPageSkeleton,
            string
          >(entryId, { ...query, include: 10 });
          if (isTypeMarketingPage(entry)) setEntry(entry);
          else setError(true);
        } catch (err) {
          console.error(err);
          setError(true);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entryId]);

    return {
      entry,
      CFQueryContainer: generateCFQueryContainer(hasLoaded, error),
    };
  }

  return (
    <ContentfulContext.Provider value={{ useMarketingPage }}>
      {children}
    </ContentfulContext.Provider>
  );
}
