import {
  ContentfulMarketingPage,
  isTypeMarketingComponentBasicComponents,
  isTypeMarketingComponentFeature,
  isTypeMarketingComponentHero,
} from '@3as-affiliates/shared/types-configs';
import { pageConfig } from './enrollment-page';

export function transformPageData(
  data: ContentfulMarketingPage,
  enrollmentUrl: string,
  aID: string,
  qID?: string
) {
  if (data && data.fields.sections) {
    const sections = data.fields.sections;
    for (let i = 0; i < sections.length; i++) {
      const entry = data.fields.sections[i];
      // Override Hero & Feature CTA Links
      if (
        entry &&
        (isTypeMarketingComponentHero(entry) ||
          isTypeMarketingComponentFeature(entry)) &&
        entry.fields.cta &&
        entry.fields.cta[0]
      ) {
        entry.fields.cta[0].fields.url = enrollmentUrl;
      }

      if (
        entry &&
        isTypeMarketingComponentBasicComponents(entry) &&
        entry.fields.content &&
        entry.fields.content[0] &&
        entry.fields.content[0].fields.cta &&
        entry.fields.content[0].fields.cta[0]
      ) {
        const isBrochureLink =
          entry.fields.content[0].fields.cta[0].fields.url === '/brochure';

        if (!entry.fields.content[0].fields.cta[0].fields.url) {
          entry.fields.content[0].fields.cta[0].fields.url = enrollmentUrl;
        }

        if (
          data.sys.id ===
            pageConfig['appliance-plus']?.acrisure?.contentfulEntryId &&
          isBrochureLink
        ) {
          entry.fields.content[0].fields.cta[0].fields.url = qID
            ? `/enrollment/home-plus/acrisure/${aID}?q_id=${qID}`
            : `/enrollment/home-plus/acrisure/${aID}`;
        } else if (
          data.sys.id ===
            pageConfig['home-plus']?.acrisure?.contentfulEntryId &&
          isBrochureLink
        ) {
          entry.fields.content[0].fields.cta[0].fields.url = qID
            ? `/enrollment/appliance-plus/acrisure/${aID}?q_id=${qID}`
            : `/enrollment/appliance-plus/acrisure/${aID}`;
        }
      }
    }
  }

  return data;
}
