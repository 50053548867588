import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { AvatarMenu } from '../avatar-menu';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';

// Styled components
const StyledContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 16px;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLoginButton = styled(Button)`
  height: 27px;
  font-size: 14px;
`;

type HeaderProps = PartnerLogoProps & {
  pageName: string;
  showLoginButton?: boolean;
  showUserAvatar?: boolean;
  onProfileClick?: () => void;
  onProvideFeedbackClick?: () => void;
};

export const Header = ({
  pageName,
  showLoginButton = false,
  showUserAvatar = false,
  partner,
  coBrand,
  logoHeight,
  onProfileClick,
  onProvideFeedbackClick,
}: HeaderProps) => {
  const { logIn, authState, logOut } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { affiliateInfo } = useAppData();

  const isLoggedIn = authState === AuthenticationState.LoggedIn;

  const handleAnalytics = (cta: string, actionId: string) => {
    dispatchClickAnalytics(
      { Cta: cta, ActionId: actionId },
      { PageName: pageName }
    );
  };

  const handleLoginClick = () => {
    handleAnalytics('Header login button', 'HeaderLoginButton');
    logIn();
  };

  const handleLogoutClick = () => {
    handleAnalytics('Header logout button', 'HeaderLogoutButton');
    logOut();
  };

  const handleHeaderClick = () => {
    handleAnalytics('Header Icon', 'HeaderIcon');
  };

  return (
    <StyledContainer onClick={handleHeaderClick}>
      <StyledLeftContainer>
        <PartnerLogo
          partner={partner}
          coBrand={coBrand}
          logoHeight={logoHeight}
        />
      </StyledLeftContainer>
      <StyledRightContainer>
        {showLoginButton && !isLoggedIn && (
          <StyledLoginButton
            onClick={handleLoginClick}
            variant="outline"
            size="small"
            iconSrc="User"
            iconSide="left"
          >
            Sign in
          </StyledLoginButton>
        )}
        {showUserAvatar && isLoggedIn && (
          <AvatarMenu
            email={affiliateInfo?.email as string}
            onProfileClick={onProfileClick}
            onProvideFeedbackClick={onProvideFeedbackClick}
            onSignOut={handleLogoutClick}
          />
        )}
      </StyledRightContainer>
    </StyledContainer>
  );
};
