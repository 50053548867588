// until we decide on a content config platform like contentful, we will use this file to store the content for the comparison
import {
  PitchAssistConfig,
  TypeProductContentConfig,
} from '@3as-affiliates/partners/web/components';
import bannerImg from '../../assets/comparion/banner-appliance-protect.png';
import tabImg from '../../assets/comparion/tab-appliance-plus.svg';

const pitchAssist: PitchAssistConfig = {
  title: 'Asurion Appliance+ pitch assist',
  collection: [
    {
      settings: {
        header: 'Offers',
        iconSrc: 'ChatBubblesCircle',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Reiterate the value of the customer&apos;s appliance investment.</strong>
            <ul>
              <li>The average American home has $10,000 worth of appliances* in their home. What&apos;s your backup plan if they break of malfunction?</li>
            </ul>
          `,
        },
        {
          text: `
              <strong>Showcase the value of Appliance+ to close the sale!</strong>
              <ul>
                <li>With the Asurion Appliance+ plan, your eligible major appliances are protected if they experience a covered breakdown. We cover kitchen appliances such as refrigerators, ovens, ranges, and dishwashers—plus washers, dryers, and more—for failures that your manufacturer&apos;s warranty may not cover. Food spoilage is also covered up to $300 per eligible refrigerator or freezer claim!</li>
                <li>For only $29.99 per month and $99 per approved claim, Asurion Appliance+ offers protection, 24/7 agent support, and covers both the eligible appliances you own today, up to 20 years old, and any eligible future purchases, as well.</li>
                <li>Why don&apos;t we get you signed up today so we can make sure your eligible appliances are protected.</li>
              </ul>
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Rebuttals',
        iconSrc: 'Refresh',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>Cost --</strong>
            ”I understand that this is an added expense that you feel your budget can&apos;t absorb. However, when something goes wrong with an appliance, it can cost hundreds or even thousands of dollars to repair or replace. With Asurion Appliance+ coverage, you can have peace of mind knowing your eligible appliances are covered for a low monthly rate and low service fee per repair. You won&apos;t have to worry about unexpected repair or replacement costs. We even cover up to $300 in lost food in the event of a covered breakdown of your eligible refrigerator or freezer. Why don&apos;t we get you signed up today?”
          `,
        },
        {
          text: `
            <strong>Already covered –-</strong>
            “Appliance+ is specifically designed to cover the repair or replacement costs when your appliances break down due to wear and tear, mechanical failure, or electrical breakdown—situations that are typically not covered by a standard homeowners insurance policy. It provides peace of mind knowing you have coverage when an unexpected breakdown occurs."
          `,
        },
        {
          text: `
           <strong>Overwhelmed by decisions --</strong>
           “I understand! If you change your mind or want more information about Asurion Appliance+, please visit {provide customer with personal link}”
          `,
        },
      ],
    },
    {
      settings: {
        header: 'Product Details',
        iconSrc: 'IconPlaceholder',
        transitionDuration: '.4s',
        transitionTimingFunction: 'cubic-bezier(.68,.12,.24,.99)',
      },
      listItems: [
        {
          text: `
            <strong>$29.99 Per month + Taxes</strong>
            <p>This Plan covers an unlimited quantity of the following products, of any size, and any brand, as outlined below, not to exceed 20 years of age at the time of claim.</p>
          `,
        },
        {
          text: `
            <strong>MAJOR HOME APPLIANCES:</strong>
            <ul>
              <li>Refrigerator(s)</li>
              <li>Freezer(s)</li>
              <li>Oven(s)</li>
              <li>Range(s)</li>
              <li>Cooktop(s)</li>
              <li>Microwave(s) (other than countertop)</li>
              <li>Dishwasher(s)</li>
              <li>Washer(s)</li>
              <li>Dryer(s)</li>
              <li>Trash Compactor(s)</li>
              <li>Freestanding Ice Maker(s)</li>
            </ul>
          `,
        },
        {
          text: `
           <strong>Does not cover: </strong>
           <p>HVAC, Water heaters, Water Tanks</p>

           <p>For a full list of coverage please see the program Terms and Conditions.</p>
          `,
        },
        {
          text: `
            <strong>Additional details</strong>
            <ul>
            <li>The plan offers appliance troubleshooting on Day 1. Other coverage starts on Day 31. Pre-existing conditions or breakdowns before Day 31 are not covered.</li>
            <li>A service fee of up to $99 plus tax applies for each approved claim.</li>
            <li>$300 food spoilage reimbursement for food losses resulting from a covered.</li>
            <li>Breakdown on a per refrigerator/freezer claim basis (Documentation is required)</li>
            <li>The plan provides up to $5,000 in coverage every 12 months and up to $5,000 per covered product per approved claim.</li>
              </ul>
            <p>Any preexisting conditions or breaks that occur before day 31 are not covered.</p>
          `,
        },
      ],
    },
  ],
};

export const asurionAppliancePlusProductContent: TypeProductContentConfig = {
  name: 'Appliance+',
  d2cUri: '/affiliate/asurion/appliance-plus/personal-info',
  tabImage: { url: tabImg, alt: 'Appliance+' },
  bannerImage: { url: bannerImg, alt: 'A collection of appliances.' },
  pitchAssist,
  // featuresConfig,
  // trainingMaterialsConfig
};
