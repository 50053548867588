import { PageContainerWithViewAnalyticsHeaderFooter } from '@3as-affiliates/partners/web/components';
import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import { AffiliationType } from '@3as-affiliates/shared/types-configs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AlertBanner,
  Button,
  colors,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AnySchema, object, string } from 'yup';

const StyledContainer = styled.div`
  height: calc(100vh - 242px);
  min-height: 390px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 435px;
`;

const Title = styled.p`
  color: ${colors.black};
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%;
  margin-bottom: 0;
`;

const Description = styled.p`
  color: ${colors.neutralDeeper};
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
  width: 100%;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const StyledDiv = styled.div`
  display: block;
`;

const Spacer = styled.div`
  margin: 10px 0;
  width: 100%;
`;

const StyledTextarea = styled.textarea`
  width: 435px;
  display: flex;
  height: 145px;
  padding: 12px 0 12px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${colors.neutralDeeper};

  ::placeholder {
    font-family: Apercu Pro,serif;
    overflow: hidden;
    color: ${colors.neutralDeeper}
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }
`;

const StyledButton = styled(Button)`
  width: 295px !important;
`;

interface ProvideFeedbackFormValues {
  feedbackDescription: string;
}

interface FormConfig {
  formValidationSchema: AnySchema;
  formDefaultValues: ProvideFeedbackFormValues;
}

const provideFeedbackConfig: FormConfig = {
  formValidationSchema: object({
    feedbackDescription: string().required(),
  }),
  formDefaultValues: {
    feedbackDescription: '',
  },
};

export const Feedback = () => {
  const navigate = useNavigate();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { appInfo, affiliateInfo } = useAppData();
  const [isOpenConfirmationBanner, setIsOpenConfirmationBanner] =
    useState(false);

  const affiliations = affiliateInfo?.affiliations as AffiliationType[];
  const defaultAffiliation = affiliations[0];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isOpenConfirmationBanner) {
        setIsOpenConfirmationBanner(false);
        navigate(Routes.LoggedInHome);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpenConfirmationBanner, navigate]);

  const submitProvideFeedback = (values: ProvideFeedbackFormValues) => {
    const extras = {
      RepId: defaultAffiliation?.externalId as string,
      RepEmail: affiliateInfo?.email as string,
      RepFullName: `${affiliateInfo?.firstName} ${affiliateInfo?.lastName}`,
      Provider: appInfo?.partner as string,
      FeedbackDescription: values.feedbackDescription as string,
    };

    dispatchClickAnalytics(
      {
        Cta: 'Provide Feedback Submitted',
        ActionId: 'ProvideFeedbackSubmit',
      },
      extras
    );

    setIsOpenConfirmationBanner(true);
  };

  const { isValid, validateForm, handleChange, values, handleSubmit } =
    useFormik<ProvideFeedbackFormValues>({
      initialValues: provideFeedbackConfig.formDefaultValues,
      validationSchema: provideFeedbackConfig.formValidationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: submitProvideFeedback,
    });

  useEffect(() => {
    validateForm && validateForm();
  }, [validateForm, values]);

  return (
    <PageContainerWithViewAnalyticsHeaderFooter
      pageName={PageNames.ProvideFeedbackPage}
    >
      <StyledContainer>
        <AlertBanner
          isOpen={isOpenConfirmationBanner}
          alertBannerType={'success'}
        >
          {'Feedback submitted, thank you!'}
        </AlertBanner>
        <TitleWrapper>
          <Title>{'Provide Feedback'}</Title>
          <Description>
            {
              'Please, provide any feedback you wish or describe any issues you encounter and we will try to fix it. We’ll get back in touch as soon as possible!'
            }
          </Description>
        </TitleWrapper>
        <Spacer />
        <StyledForm onSubmit={handleSubmit}>
          <FormControlsWrapper>
            <StyledDiv>
              <StyledTextarea
                onChange={handleChange}
                name={'feedbackDescription'}
                rows={3}
                placeholder={'Description...'}
                value={values.feedbackDescription}
              />
            </StyledDiv>
          </FormControlsWrapper>
          <ControlWrapper>
            <Spacer />
            <Spacer />
            <StyledButton disabled={!isValid} type="submit">
              {'Submit'}
            </StyledButton>
          </ControlWrapper>
        </StyledForm>
      </StyledContainer>
    </PageContainerWithViewAnalyticsHeaderFooter>
  );
};
