import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { Partner, Products } from '@3as-affiliates/shared/types-configs';
import { PageContainerWithViewAnalytics } from '@3as-affiliates/shared/web/components';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NotFound } from './NotFound';

const PdfContainer = styled.iframe`
  height: calc(100vh);
  width: 100%;
`;

type pageAsset = {
  pageName: PageNames;
  assetName: string;
};
type partnerPageMap = Record<string, pageAsset>;
export type ProductTermsMap = Record<Products, partnerPageMap>;

const productTermsMap: ProductTermsMap = {
  [Products.HOME_PLUS]: {
    [Partner.COMPARION]: {
      pageName: PageNames.TermsConditions_HomePlus_Comparion,
      assetName: 'Comparion_HomePlus_AWS-618-CMP2-1223',
    },
    [Partner.ACRISURE]: {
      pageName: PageNames.TermsConditions_HomePlus_Acrisure,
      assetName: 'Acrisure_HomePlus_ASP-551-ACR1-0323',
    },
  },
  [Products.APPLIANCE_PROTECT]: {
    [Partner.COMPARION]: {
      pageName: PageNames.TermsConditions_ApplianceProtect_Comparion,
      assetName: 'Comparion_ApplianceProtect_AWS-618-CMP2_5-2-24',
    },
  },
  [Products.APPLIANCE_PLUS]: {
    [Partner.ACRISURE]: {
      pageName: PageNames.TermsConditions_AppliancePlus_Acrisure,
      assetName: 'Acrisure_AppliancePlus_ASP-501-MA1-1218',
    },
  },
  [Products.RENTER_TECH_PROTECT]: {
    [Partner.REALPAGE]: {
      pageName: PageNames.TermsConditions_RenterTechProtect_Realpage,
      assetName: 'Realpage_RenterTechProtect_ASP-551-0323-CL-Final_6-5-24',
    },
  },
};
export const getTermsAndConditionsParams = (
  product: Products,
  partner: Partner
): pageAsset | null => {
  if (productTermsMap[product] && productTermsMap[product][partner]) {
    return productTermsMap[product][partner];
  } else {
    return null;
  }
};

export const TermsAndConditionsPartners = () => {
  const { product, partner } = useParams();

  const tcParams = getTermsAndConditionsParams(
    product as Products,
    partner as Partner
  );

  if (!tcParams) {
    return (
      <NotFound
        pageName={`${PageNames.TermsConditions} | Partner: ${partner} | Product: ${product}`}
      />
    );
  }

  return (
    <PageContainerWithViewAnalytics pageName={tcParams.pageName}>
      <PdfContainer
        src={`../assets/terms-and-conditions/${tcParams.assetName}.pdf`}
      />
    </PageContainerWithViewAnalytics>
  );
};
