// until we decide on a content config platform like contentful, we will use this file to store the content for the comparison
import { TypeProductContentConfig } from '@3as-affiliates/partners/web/components';
import bannerImg from '../../assets/comparion/banner-appliance-protect.png';
import tabImg from '../../assets/comparion/tab-appliance-plus.svg';

export const acrisureAppliancePlusProductContent: TypeProductContentConfig = {
  name: 'Appliance+',
  d2cUri: '/affiliate/acrisure/appliance-plus/personal-info',
  tabImage: { url: tabImg, alt: 'Appliance+' },
  bannerImage: { url: bannerImg, alt: 'A collection of appliances.' },
  pitchAssist: null,
  // featuresConfig,
  // trainingMaterialsConfig
};
