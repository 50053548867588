import {
  ContentfulBasicComponent,
  ContentfulCardComponent,
  ContentfulSpacing,
} from '@3as-affiliates/shared/types-configs';
import {
  Link,
  LinkProps,
  Text,
  breakpoints,
} from '@soluto-private/mx-asurion-ui-react-v3';
import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';
import { contentfulSpacing, grid } from '../functional-css';

const Headline = styled(Text)``;
const Subheading = styled(Text)`
  p {
    margin: 0;
    white-space: break-spaces;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Intro = styled(IntroComponent)`
  ${(fields) => contentfulSpacing(fields)}
  ${grid};
  row-gap: 2rem;
  text-align: center;

  ${Headline}, ${Subheading}, ${LinkGroup} {
    grid-column-start: 3;
    grid-column: span 6 / span 6;

    ${breakpoints.md} {
      grid-column: span 8 / span 8;
      grid-column-start: 3;
    }
  }
`;

export type TypeIntroComponentPassthroughFields = ContentfulSpacing & {
  classes?: string;
  content: Array<{ fields: ContentfulCardComponent['fields'] }>;
};

type TypeEntity = ContentfulBasicComponent;
type TypeFields = TypeEntity['fields'] | TypeIntroComponentPassthroughFields;
type ComponentProps = { className?: string } & TypeFields;

function IntroComponent({ className = '', ...fields }: ComponentProps) {
  if (!fields.content[0]) return null;
  const headline = fields.content[0].fields.headline;
  const subheading = fields.content[0].fields.subheading;
  const cta = fields.content[0].fields.cta;

  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      <Headline forwardedAs={'h2'} weight={'feather'} size={5}>
        {headline}
      </Headline>
      {subheading && (
        <Subheading forwardedAs={'span'} weight={'feather'} size={3}>
          <Markdown>{subheading}</Markdown>
        </Subheading>
      )}
      {cta && cta?.[0] && (
        <LinkGroup>
          <Link
            href={cta?.[0].fields.url}
            variant={'button'}
            btnVariantProps={
              { btnSize: 'large' } as LinkProps['btnVariantProps']
            }
          >
            {cta?.[0].fields.text}
          </Link>
        </LinkGroup>
      )}
    </div>
  );
}
