import {
  defaultLoggedOutRoute,
  PageNames,
  Routes,
} from '@3as-affiliates/partners/web/types-configs';
import { AuthRedirectCallback } from '@3as-affiliates/shared/web/features';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import {
  DeprecatedEnrollmentRoute,
  EnrollmentRoute,
  Feedback,
  LandingPage,
  NoAuthSalesProductPage,
  PrivacyNotice,
  SalesProductDeclinePage,
  SalesProductPage,
  TermsAndConditionsPartners,
  TermsConditions,
} from '../pages';
import { Unauthorized } from '../pages/Unauthorized';
import { RouteWithUserInfo } from './RouteWithUserInfo';

export const router = createBrowserRouter([
  {
    path: Routes.AuthRedirectCallback,
    element: (
      <AuthRedirectCallback
        homePageRoute={Routes.LoggedInHome}
        pageName={PageNames.AuthRedirectPage}
      />
    ),
  },
  {
    path: Routes.LoggedOutLanding,
    element: <LandingPage />,
  },
  {
    path: Routes.LoggedInHome,
    element: <RouteWithUserInfo />,
    children: [
      {
        path: '',
        element: <SalesProductPage />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
      },
      {
        path: Routes.SalesProduct,
        element: <SalesProductPage />,
      },
      {
        path: Routes.SalesProductDecline,
        element: <SalesProductDeclinePage />,
      },
    ],
  },
  {
    path: Routes.AgentEnrollmentNoAuthWithProduct,
    element: <NoAuthSalesProductPage />,
  },
  {
    path: Routes.AgentEnrollmentNoAuth,
    element: <NoAuthSalesProductPage />,
  },
  {
    path: Routes.DeprecatedEnrollmentURLHomePlusComparion,
    element: <DeprecatedEnrollmentRoute />,
  },
  {
    path: Routes.Enrollment,
    element: <EnrollmentRoute />,
  },
  {
    path: Routes.TermsConditions,
    element: <TermsConditions />,
  },
  {
    path: Routes.TermsConditionsPartners,
    element: <TermsAndConditionsPartners />,
  },
  {
    path: Routes.Unauthorized,
    element: <Unauthorized />,
  },
  {
    path: Routes.PrivacyNotice,
    element: <PrivacyNotice />,
  },
  {
    path: '*',
    element: <Navigate to={defaultLoggedOutRoute} />,
  },
]);
