import { LegalCopyTailwindsSubset } from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { PageContainerWithViewAnalytics } from '@3as-affiliates/shared/web/components';

/*
 * LEGAL COPY LAST COPIED FROM https://www.asurion.com/terms-conditions ON JANUARY 15TH, 2024
 */

export const TermsConditions = () => {
  return (
    <LegalCopyTailwindsSubset>
      <PageContainerWithViewAnalytics pageName={PageNames.TermsConditions}>
        <Content />
      </PageContainerWithViewAnalytics>
    </LegalCopyTailwindsSubset>
  );
};

function Content() {
  return (
    <main id="main-content">
      <div
        data-testid="intro"
        className="relative mx-auto max-w-screen-2xl overflow-hidden bg-white bg-cover bg-center bg-no-repeat px-6 py-10 md:px-12 lg:py-20 lg:pb-12"
      >
        <div className="container relative mx-auto grid grid-cols-12">
          <h1
            className="text-4 md:text-5 xl:text-6  col-span-12 text-center text-black lg:col-span-8 lg:col-start-3"
            data-testid="header-tag"
          >
            Website Terms of Use
          </h1>
        </div>
      </div>
      <div
        data-testid="copy-block"
        className=" copy-block mx-auto max-w-screen-2xl bg-white px-6 text-left"
      >
        <div className="copyblock-body container mx-auto grid grid-cols-12">
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Effective Date: November 30, 2021.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">SCOPE OF USE</span>.&nbsp;Asurion, LLC,
            on behalf of itself and for the benefit of its affiliates and
            subsidiaries (“We”, “Us” and “Our” and any derivation thereof)
            provides this Website (the “Site”) to you, the user of the Site
            (“You”), for Your informational, noncommercial use, and subject to
            the following Terms of Use (the “Terms”). You shall use the Site
            strictly in accordance with these Terms and in a manner consistent
            with all applicable local, state, national and international laws
            and regulations. Use of the Site is limited to persons eighteen (18)
            years of age or older. Using this Site to evaluate whether to enter
            into a business relationship with Us shall not constitute a
            commercial use for the purposes hereof. It is a violation of these
            Terms for You to use the Site in violation of any applicable laws
            and regulations or in violation of the rules of any of Our service
            providers. Certain other programs or services provided by Us through
            linked websites or other channels may have additional terms and
            conditions regarding Your use of those services, and nothing in
            these Terms is intended to modify such terms and conditions. Neither
            these Terms, nor any content, materials, features, or products and
            services of the Site create any partnership, joint venture,
            employment, or other agency relationship between You and Us. You may
            not enter into any contract on Our behalf or bind Us in any way.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">ACCEPTANCE OF THE TERMS</span>. Please
            read these Terms carefully before you start to use the Site. By
            using the Site or by clicking to accept or agree to the Terms when
            this option is made available to you, you accept and agree to be
            bound and abide by these Terms and the terms of our privacy policy
            which can be viewed here:&nbsp;
            <a
              href="https://www.asurion.com/privacy-policy/"
              className="text-primary underline"
            >
              https://www.asurion.com/privacy-policy/
            </a>
            &nbsp;hereinafter the (“Privacy Policy”). This Privacy Policy
            explains Our practices relating to the collection and use of Your
            information through or in connection with our Site. &nbsp;The
            Privacy Policy is incorporated into these Terms, and governs Our use
            of Your information and any information You submit or otherwise make
            available to us in connection with the Site. IF YOU ARE UNWILLING TO
            ACCEPT THESE TERMS YOU MAY NOT USE THIS SITE.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">MODIFICATIONS.</span>&nbsp;We reserve
            the right to modify this Site and update the rules and regulations
            governing its use, at any time, including, without limitation, these
            Terms. Modifications will be posted on the Site and the “Last
            Updated” date at the top of this web page will be revised. All
            modifications are effective immediately when We post them and apply
            to all access to and use of the Site there. You understand and agree
            that if You use this Site after the date on which the Terms have
            changed, We will treat Your use as acceptance of the updated Terms.
            We may make changes in the products and/or services described in
            this Site at any time.&nbsp; You are expected to check this page
            from time to time so you aware of any changes, as they are binding
            on you.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">REGISTRATION.</span>&nbsp;To access the
            Site or some of the resources it offers, You may be asked to provide
            certain registration details or other information. You agree that
            all information You provide to register with this Site or otherwise,
            including, but not limited to, through the use of any interactive
            features on the Website, is governed by our Privacy Policy and You
            consent to all actions we take with respect to your information
            consistent with the terms of Our Privacy Policy. If You use any of
            Our services and provide Us any information through the Site, such
            as Your name, phone number, contact details, email address, wireless
            carrier information, Your password and/or personal identification
            number (PIN) (collectively, Your “Account Data”) for any accounts
            associated with Your device (“Your Account(s)”), You agree to
            provide true, accurate, current, complete and up-to-date
            information. If You provide any information that is untrue,
            inaccurate, non-current or incomplete, or We have reasonable grounds
            to suspect that such information is untrue, inaccurate, non-current
            or incomplete, then We have the right to restrict, terminate or
            refuse any and all current or future access or use of the Site (or
            any portion thereof) in our sole discretion and without notice.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">ACCESS TO SITE</span>. We reserve the
            right to withdraw or amend the materials contained on or displayed
            on the Site including the operation and offering of Site itself in
            our sole discretion without notice. Your account gives You access to
            certain portions of the Site and functionality that We may establish
            and maintain from time to time and in Our sole discretion. We will
            not be liable if for any reason all or any part of the Site is
            unavailable at any time or for any period. From time to time, We may
            restrict access to some parts of the Site, or the entire Site to
            users, including registered users. We have the right to disable any
            user name, password, or other identifier, whether chosen by You or
            provided by us, at any time in our sole discretion for any or no
            reason including if in Our opinion You have violated any of these
            Terms. You are responsible for making all of the arrangements
            necessary for You to have access to the Site. You are responsible
            for ensuring that all persons who access the Site through Your
            internet connection are aware of these Terms and comply with them.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">CONNECTION REQUIREMENTS.</span>&nbsp;You
            are responsible for providing and maintaining, at Your own risk,
            option and expense, any hardware, software and communication lines
            required to access and use the Site, and We reserve the right to
            change the access configuration of the Site at any time without
            prior notice.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">SECURITY</span>. You are responsible for
            any activity that occurs through Your account and You agree You will
            not sell, transfer, license or assign Your account, username, or any
            account rights. With the exception of people or businesses that are
            expressly authorized to create accounts on behalf of their employers
            or clients, We prohibit the creation of and You agree that You will
            not create an account for anyone other than Yourself. If You are
            using the Site on behalf of a legal entity, You represent that You
            are authorized to enter into and agree to these Terms on behalf of
            that legal entity. We reserve the right to take any action that We
            deem necessary to ensure the security of the Sites and Your Account,
            including without limitation changing Your password, terminating
            Your Account, or requesting additional information to authorize
            transactions on Your Account. You are solely responsible for keeping
            Your Account Data and any security questions and responses
            associated with Your Account confidential. Anyone with knowledge of
            or access to Your Account Data or the security questions and
            responses associated with Your Account can use that information to
            gain access to Your Account. You are solely liable for any claims,
            damages, losses, costs or other liabilities resulting from or caused
            by any failure to keep Your Account Data and the security questions
            and responses confidential, whether such failure occurs with or
            without Your knowledge or consent. You will immediately notify Us of
            any suspected or actual unauthorized access to or use of Your
            Account Data or any other breach of Your Account security. You
            hereby authorize and consent to the collection, storage, sharing,
            use, transfer and sale by Us and Our service providers, of any
            information and data related to or derived from Your use of the
            Site.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">NO WARRANTIES.</span>&nbsp;ALL CONTENT
            ON THIS SITE IS PROVIDED TO YOU ON AN “AS-IS,” “AS-AVAILABLE” BASIS
            AND WE EXPRESLY DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND
            EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, SATISFACTORY QUALITY, TIMELY, OR FREEDOM FROM
            COMPUTER VIRUS OR MALICIOUS CODE. WE MAKE NO WARRANTY AS TO THE
            ACCURACY, COMPLETENESS, CURRENCY OR RELIABILITY OF ANY CONTENT
            AVAILABLE THROUGH THIS SITE, OR THAT ANY SUCH PROBLEMS WILL BE
            CORRECTED. YOU ARE RESPONSIBLE FOR TAKING ALL PRECAUTIONS NECESSARY
            TO ENSURE THAT ANY CONTENT YOU MAY OBTAIN FROM THE SITE IS FREE OF
            VIRUSES OR MALWARE. USE OF THE SITE IS AT YOUR SOLE RISK. WITHOUT
            LIMITING THE FOREGOING, NEITHER WE NOR ANYONE ASSOCIATED WITH US
            REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE,
            RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
            CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE
            FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR
            NEEDS OR EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW, THE
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            A PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
            THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">DISCLAIMER:</span>&nbsp;Certain products
            and services described on the Site may not be available to all
            users.&nbsp; Content and other information contained on the Site has
            been prepared as a convenience to its users and is not intended to
            constitute advice or recommendations upon which a user may rely. We
            have used reasonable efforts in collecting, preparing and providing
            quality information and material, but makes no warranty or guarantee
            about the accuracy, completeness, or adequacy of the information
            contained in or linked to the Site. You must rely on information
            from the Site at Your own risk. The information and descriptions
            contained herein are not intended to be complete descriptions of the
            terms, exclusions and conditions applicable to the products and
            services, but are provided solely for general informational
            purposes; please refer to the relevant product or services agreement
            for complete terms and conditions. Should You purchase a product or
            service from Us or a third party, the terms and conditions
            applicable to that transaction will govern such entry or purchase,
            as applicable, and Your use of the Site does not affect that
            purchase in any manner. You understand that We cannot and do not
            guarantee or warrant that files available for downloading from the
            internet or the Site will be free of viruses or other destructive
            code. You are responsible for implementing sufficient procedures and
            checkpoints to satisfy your particular requirements for anti-virus
            protection and accuracy of data input and output, and for
            maintaining a means external to our Site for any reconstruction of
            any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
            LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, RANSOMWARE VIRUSES, OR OTHER
            TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
            EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
            DUE TO YOUR USE OF THE SITE OR ANY PRODUCT OR SERVICES OR ITEMS
            OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
            POSTED ON IT, OR ON ANY SITE LINKED TO IT.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">TRADEMARKS.</span>&nbsp;All trademarks,
            service marks and logos that are used or displayed on the Sites are
            owned by Us or by third parties and are the property of their
            respective owners. We own various trademarks, service marks and
            logos that may be used on the Site. You must obtain Our written
            permission prior to using any trademark or service mark of Ours.
            Unauthorized use of any trademarks, service marks or logos used on
            the Site may be a violation of state, national and international
            trademark laws. Additionally, Our custom icons, graphics, logos and
            scripting on the Site are covered by trademark, trade dress,
            copyright or other proprietary right law, and may not be copied,
            modified or used, in whole or in part, without Our prior written
            permission.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">RESTRICTIONS ON USE</span>. You may use
            the Site only for lawful purposes and in accordance with these Terms
            of Use. You agree not to use the Site:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li className="item mb-2 text-base text-black">
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li className="item mb-2 text-base text-black">
              To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with the Content Standards set out
              in these Terms of Use.
            </li>
            <li className="item mb-2 text-base text-black">
              To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any “junk mail,” “chain letter,” “spam,” or any other similar
              solicitation.
            </li>
            <li className="item mb-2 text-base text-black">
              To impersonate or attempt to impersonate Us, an employee, another
              user, or any other person or entity (including, without
              limitation, by using email addresses or screen names associated
              with any of the foregoing).
            </li>
            <li className="item mb-2 text-base text-black">
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Site, or which, as determined by Us, may
              harm the Us or users of the Website, or expose them to liability.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Additionally, you agree not to:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Use the Site in any manner that could disable, overburden, damage,
              or impair the Site or interfere with any other party’s use of the
              Site, including their ability to engage in real time activities
              through the Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Use any robot, spider, or other automatic device, process, or
              means to access the Site for any purpose, including monitoring or
              copying any of the material on the Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Use any manual process to monitor or copy any of the material on
              the Site, or for any other purpose not expressly authorized in
              these Terms of Use, without our prior written consent.
            </li>
            <li className="item mb-2 text-base text-black">
              Use any device, software, or routine that interferes with the
              proper working of the Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.
            </li>
            <li className="item mb-2 text-base text-black">
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Site, the server on which the Site is
              stored, or any server, computer, or database connected to the
              Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Share Your passcode, password, authentication credentials with any
              unauthorized third party which would enable that third party to
              obtain unauthorized access to the Site. &nbsp;
            </li>
            <li className="item mb-2 text-base text-black">
              Attack the Site via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li className="item mb-2 text-base text-black">
              Otherwise attempt to interfere with the proper working of the
              Site.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">USER CONTRIBUTIONS.&nbsp;</span>The Site
            may contain message boards, chat rooms, personal web pages or
            profiles, forums, bulletin boards, and other interactive features
            (collectively, “
            <span className="font-bold">Interactive Services</span>“) that allow
            users to post, submit, publish, display, or transmit to other users
            or other persons (hereinafter, “
            <span className="font-bold">post</span>“) content or materials
            (collectively, “
            <span className="font-bold">User Contributions</span>“) on or
            through the Site.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            All User Contributions must comply with the Content Standards set
            out in these Terms of Use.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Any User Contribution You post to the Site will be considered
            non-confidential and non-proprietary. By providing any User
            Contribution on the Website, You grant us and our affiliates and
            service providers, and each of their and our respective licensees,
            successors, and assigns the right to use, reproduce, modify,
            perform, display, distribute, and otherwise disclose to third
            parties any such material for any purpose according to Your account
            settings, as applicable.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            You represent and warrant that:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to us and our
              affiliates and service providers, and each of their and our
              respective licensees, successors, and assigns.
            </li>
            <li className="item mb-2 text-base text-black">
              All of Your User Contributions do and will comply with these Terms
              of Use.
            </li>
            <li className="item mb-2 text-base text-black">
              You understand and acknowledge that You are responsible for any
              User Contributions You submit or contribute, and You, not Us, have
              full responsibility for such content, including its legality,
              reliability, accuracy, and appropriateness.
            </li>
            <li className="item mb-2 text-base text-black">
              We are not responsible or liable to any third party for the
              content or accuracy of any User Contributions posted by You or any
              other user of the Site.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              MONITORING, ENFORCEMENT AND TERMINATION.&nbsp;
            </span>
            We neither actively monitor general use of the Site under normal
            circumstances nor exercise editorial control over the content of any
            third party’s website, e-mail transmission, news group, or other
            material created or accessible over or through this Site. However,
            We reserve the right to:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Monitor use of the Site at any time as We deem appropriate.
            </li>
            <li className="item mb-2 text-base text-black">
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
            </li>
            <li className="item mb-2 text-base text-black">
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Contribution violates the Terms of Use,
              including the Content Standards, infringes any intellectual
              property right or other right of any person or entity, threatens
              the personal safety of users of the Site or the public, or could
              create liability for Us.
            </li>
            <li className="item mb-2 text-base text-black">
              Disclose Your identity or other information about You to any third
              party who claims that material posted by You violates their
              rights, including their intellectual property rights or their
              right to privacy.
            </li>
            <li className="item mb-2 text-base text-black">
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Block, terminate or suspend your access to all or part of the Site
              for any or no reason, including without limitation, any violation
              of these Terms of Use.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Without limiting the foregoing, we have the right to cooperate fully
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            posting any materials on or through the Site. YOU WAIVE AND HOLD
            HARMLESS US AND OUR AFFILIATES, LICENSEES, AND SERVICE PROVIDERS
            FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US DURING, OR
            TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER US OR LAW
            ENFORCEMENT AUTHORITIES.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            However, We do not undertake to review all material before it is
            posted on the Site, and cannot ensure prompt removal of
            objectionable material after it has been posted. Accordingly, We
            assume no liability for any action or inaction regarding
            transmissions, communications, or content provided by any user or
            third party. We have no liability or responsibility to anyone for
            performance or nonperformance of the activities described in this
            section.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">CONTENT STANDARDS.</span>These content
            standards apply to any and all User Contributions and use of
            Interactive Services. User Contributions must in their entirety
            comply with all applicable federal, state, local, and international
            laws and regulations. Without limiting the foregoing, User
            Contributions must not:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Contain any material that is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory, or
              otherwise objectionable.
            </li>
            <li className="item mb-2 text-base text-black">
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation, or age.
            </li>
            <li className="item mb-2 text-base text-black">
              Infringe any patent, trademark, trade secret, copyright, or other
              intellectual property or other rights of any other person.
            </li>
            <li className="item mb-2 text-base text-black">
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Use and our Privacy Policy.
            </li>
            <li className="item mb-2 text-base text-black">
              Be likely to deceive any person.
            </li>
            <li className="item mb-2 text-base text-black">
              Promote any illegal activity, or advocate, promote, or assist any
              unlawful act.
            </li>
            <li className="item mb-2 text-base text-black">
              Cause annoyance, inconvenience, or needless anxiety or be likely
              to upset, embarrass, alarm, or annoy any other person.
            </li>
            <li className="item mb-2 text-base text-black">
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
            </li>
            <li className="item mb-2 text-base text-black">
              Involve commercial activities or sales, such as contests,
              sweepstakes, and other sales promotions, barter, or advertising.
            </li>
            <li className="item mb-2 text-base text-black">
              Give the impression that they emanate from or are endorsed by Us
              or any other person or entity, if this is not the case.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">WEBSITE CONTENT &amp; COPYRIGHT.</span>
            &nbsp;This Site and the content contained on this Site is protected
            by U.S. and international copyright laws. You may not modify,
            reproduce or distribute the content, design or layout of the Site or
            individual sections of the content, design or layout of the Site or
            Our trademarks or logos without our written permission. The layout,
            compilation, organization and display of the content as well as all
            software and inventions used on and in connection with this Site are
            Our exclusive property (the “Content”). We reserve all of Our rights
            in the Site and its Content. You may download information from the
            Site and print out a hard copy for Your personal use provided that
            You keep intact and do not remove or alter any copyright or other
            notice (e.g., trademark, patent, etc.) contained in the information.
            We do not warrant or represent that Your use of content, products
            and services, or any other materials displayed on the Site will not
            infringe rights of third parties.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              COPYRIGHT INFRINGEMENT NOTIFICATION.
            </span>
            &nbsp;We have a policy of removing data or Content that violates
            applicable copyright law. Pursuant to Section 512 of Title 17 of the
            United States Code, We have implemented procedures for receiving
            written notification of claimed copyright infringement and for
            processing such claims in accordance with the law. To file a
            copyright infringement notification with us, You will need to send a
            written communication to Our Designated Agent, listed below,
            containing substantially the following information or such other
            information as may be required by Section 512(c)(3) of the Copyright
            Act: (i) a physical or electronic signature of a person authorized
            to act on behalf of the owner of an exclusive right that is
            allegedly infringed; (ii) identification of the copyrighted work
            claimed to have been infringed, or, if multiple copyrighted works at
            a single online site are covered by a single notification, a
            representative list of such works at that site; (iii) identification
            of the material that is claimed to be infringing or to be the
            subject of infringing activity and that is to be removed or access
            to which is to be disabled, and information reasonably sufficient to
            permit the service provider to locate the material; (iv) information
            reasonably sufficient to permit the service provider to contact the
            complaining party, such as an address, telephone number, and, if
            available, an electronic mail address at which the complaining party
            may be contacted; (v) a statement that the complaining party has a
            good faith belief that use of the material in the manner complained
            of is not authorized by the copyright owner, its agent, or the law;
            and (vi) a statement that the information in the notification is
            accurate, and under penalty of perjury, that the complaining party
            is authorized to act on behalf of the owner of an exclusive right
            that is allegedly infringed. Please direct any such notice to Our
            Legal Department, 140 11th Ave. North, Nashville, TN 37203 or
            contact by email at DMCAnotification@asurion.com. The foregoing
            contact information is for use solely with notice of copyright
            infringement and You may not receive a response to any other
            communications, feedback, comments or requests for technical support
            delivered to the foregoing address or e-mail.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              RELIANCE ON INFORMATION POSTED.&nbsp;
            </span>
            The information presented on or through the Site is made available
            solely for general information purposes. We do not warrant the
            accuracy, completeness, or usefulness of this information. Any
            reliance You place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by You or any other visitor to the
            Site, or by anyone who may be informed of any of its contents.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            This Site may include content provided by third parties, including
            materials provided by other users, bloggers, and third-party
            licensors, syndicators, aggregators, and/or reporting services. All
            statements or opinions expressed in these materials, and all
            articles and responses to questions and other content, other than
            the content provided by Us, are solely the opinions and the
            responsibility of the person or entity providing those materials.
            These materials do not necessarily reflect Our opinion. We are not
            responsible, or liable to You or any third party, for the content or
            accuracy of any materials provided by any third parties.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              LINKING TO THE SITE AND SOCIAL MEDIA FEATURES
            </span>
            . You may link to Our Site, provided You do so in a way that is fair
            and legal and does not damage Our reputation or take advantage of
            it, but You must not establish a link in such a way as to suggest
            any form of association, approval, or endorsement on Our part
            without Our express written consent.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            This Site may provide certain social media features that enable You
            to:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Link from Your own or certain third-party websites to certain
              content on this Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Send emails or other communications with certain content, or links
              to certain content, on this Site.
            </li>
            <li className="item mb-2 text-base text-black">
              Cause limited portions of content on this Site to be displayed or
              appear to be displayed on Your own or certain third-party
              websites.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            You may use these features solely as they are provided by Us, and
            solely with respect to the content they are displayed with, and
            otherwise in accordance with any additional terms and conditions we
            provide with respect to such features. Subject to the foregoing, You
            must not:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Establish a link from any website that is not owned by You.
            </li>
            <li className="item mb-2 text-base text-black">
              Cause the Site or portions of it to be displayed on, or appear to
              be displayed by, any other site, for example, framing, deep
              linking, or in-line linking.
            </li>
            <li className="item mb-2 text-base text-black">
              Link to any part of the Site other than the homepage.
            </li>
            <li className="item mb-2 text-base text-black">
              Otherwise take any action with respect to the materials on this
              Site that is inconsistent with any other provision of these Terms
              of Use.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            The website from which You are linking, or on which you make certain
            content accessible, must comply in all respects with the Content
            Standards set out in these Terms of Use.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            You agree to cooperate with Us in causing any unauthorized framing
            or linking immediately to stop. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We may disable all or any social media features and any links at any
            time without notice in our discretion.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">INDEMNITY.</span>&nbsp;You agree to
            indemnify and hold Us and Our subsidiaries, affiliates, officers,
            agents, employees, partners, contractors, and licensors harmless
            from and against any and all suits, actions, losses, claims,
            proceedings, demands, expenses, damages, settlements, judgments,
            injuries, liabilities, obligations, risks, and costs, including,
            without limitation, litigation expenses and reasonable attorneys’
            fees, made by any third party due to or arising out of Your use of
            the Site, Your use of any information obtained from the Site, Your
            User Contributions, any use of the Site’s content, services and
            products other than as expressly authorized in these Terms, Your
            violation of these Terms, or Your violation of any law or any rights
            of another. We reserve the right, at Your expense, to assume the
            exclusive defense and control of any matter which You are required
            to indemnify against, and You agree to cooperate in Our defense of
            such matter. This indemnification shall survive any termination of
            these Terms.&nbsp;
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">LIMITATION OF LIABILITY.&nbsp;</span>TO
            THE FULLEST EXTENT BY LAW, YOU UNDERSTAND AND AGREE THAT IN NO EVENT
            WILL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            CONSEQUENTIAL OR SPECIAL DAMAGES OF ANY KIND, WHETHER BASED IN
            CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, ARISING OUT OF OR IN
            ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE SITE, EVEN IF YOU
            HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT
            NOT LIMITED TO: (A) ANY ACTION TAKEN IN CONNECTION WITH AN
            INVESTIGATION BY LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY
            OTHER PARTY’S USE OF THE SITES (B) ANY ACTION TAKEN IN CONNECTION
            WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (C) ANY DAMAGE
            TO ANY USER’S COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR
            TECHNOLOGY; AND (D) RELIANCE BY ANY PARTY ON ANY CONTENT OBTAINED
            THROUGH USE OF THIS SITE, OR THAT ARISES IN CONNECTION WITH MISTAKES
            OR OMISSIONS IN, OR DELAYS IN TRANSMISSION OF, INFORMATION TO OR
            FROM THE USER, INTERRUPTIONS IN TELECOMMUNICATIONS CONNECTIONS TO
            THE SITE OR VIRUSES, WHETHER CAUSED IN WHOLE OR IN PART BY
            NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, THEFT OR
            DESTRUCTION OF, OR UNAUTHORIZED ACCESS TO THE SITE, OR RELATED
            INFORMATION OR PROGRAMS. YOU UNDERSTAND AND AGREE THAT YOUR USE OF
            THE SITE IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE US AND
            OUR AFFILIATES DIRECTLY OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR
            ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE SITE.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL
            THEORIES WILL BE LIMITED TO THE AMOUNT YOU HAVE PAID FOR THE USE OF
            THE SITE, IF ANY, AND IF YOU HAVE PAID NO AMOUNT, THEN $50.&nbsp;
            YOU ALSO ACKNOWLEDGE AND AGREE THAT YOU HAVE VIEWED OR USED THE SITE
            WITH A FULL UNDERSTANDING OF THE LIMITATION OF OUR LIABILITY IN THIS
            AGREEMENT.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            BY ACCESSING THE SITE, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS
            WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED,
            AND IN ACCORDANCE WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE
            READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF
            SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY SIMILAR LAW OF
            ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: “A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
            OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
            SETTLEMENT WITH THE DEBTOR.”
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Some jurisdictions do not allow exclusions of liability for certain
            types of damages. Accordingly, some of the above limitations may not
            apply to You to the extent prohibited by law. In such cases, Our
            liability will be limited to the fullest extent permitted by
            applicable law.&nbsp;
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">THIRD-PARTY WEBSITES.</span>&nbsp;Some
            links in this Site may navigate You away from Our Site or redirect
            You to other websites, including websites operated by third parties.
            Please be aware that those websites may collect Personally
            Identifiable Information (PII) from or about You and Non-PII about
            Your visit. The linked sites are not under Our control, and the
            content available on the internet sites linked to this Site does not
            necessarily reflect Our opinion. We are providing these links to
            other internet sites as a convenience to You, and access to any
            other internet sites linked to this Site is at Your own risk. The
            inclusion of any link does not imply a recommendation or endorsement
            by Us of the linked site. You should review the terms of use and
            privacy policies that are posted on any website that You visit,
            before using any linked websites. We accept no liability for any
            information, products, advertisements, content, services or software
            accessible through these third-party websites or for any action You
            may take as a result of linking to any such website. We are under no
            obligation to maintain any link on the Site and may remove a link at
            any time in its sole discretion for any reason whatsoever. We shall
            not be responsible or liable, directly or indirectly, for any
            damages or losses caused or alleged to be caused by or in connection
            with the use of or reliance on such content, products, services or
            other materials available on or through any such website. We are not
            responsible for the privacy practices of any other websites.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">RULES FOR SURVEYS.&nbsp;</span>Any
            surveys or other similar voluntary customer information collection
            and analysis efforts (collectively, “Surveys”) made available
            through the Site will be governed by additional specific terms that
            are separate from these Terms.&nbsp; By participating in any such
            Survey, You will become subject to those terms, which may vary from
            the terms and conditions set forth herein.&nbsp; We urge You to read
            the applicable terms, which are linked from the particular Survey,
            and to review our Privacy Policy which, in addition to these Terms,
            governs any information You submit in connection with any such
            Survey.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">ELECTRONIC COMMUNICATIONS.</span>
            &nbsp;When You visit the Site or send e-mails to Us, You are
            communicating with Us electronically. By providing Us Your email
            address, You consent to Our using the email address to send You
            notices related to the Site, including any notices required by law,
            in lieu of communication by postal mail. Where appropriate we will
            communicate with You by e-mail or by posting notices on the Site. We
            may also use Your email address to send You other messages, such as
            changes to features of the Site and special offers. You agree that
            all agreements, notices, disclosures and other communications that
            We provide to You electronically satisfy any legal requirement that
            such communications be in writing. You further agree that any
            notices provided by Us electronically are deemed to be given and
            received on the date We transmit any such electronic communication
            as described in these Terms. Please see Your service specific terms
            and privacy policy for information on opting out of electronic
            communications.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              GOVERNING LAW AND GENERAL PRINCIPLES.
            </span>
            &nbsp;We operate this Site from offices within the United States of
            America. We make no representation that the information in the Site
            is appropriate or available for use in other locations, and access
            to the Site from territories where the contents of the Site may be
            illegal is prohibited. Those who choose to access this Site from
            other locations do so, on their own initiative and are responsible
            for compliance with applicable local laws. By using the Site,
            regardless of where You live or are located in the world, You
            consent to these Terms and any claims relating to the information,
            services or products made available through this Site will be
            governed by the laws of the State of Tennessee, U.S.A., excluding
            the application of its conflicts of law rules. Any legal proceedings
            arising from or relating to these Terms of Use shall be brought
            exclusively in the federal or state courts of Davidson County,
            Tennessee and You hereby consent to the personal jurisdiction and
            venue of such courts. You agree that any claim You may have arising
            out of or related to Your use of the Site or Your relationship with
            Us must be filed within one (1) year after such claim arose;
            otherwise, Your claim is permanently barred. If any provision of
            these Terms shall be deemed unlawful, void, or for any reason
            unenforceable, then that provision will be deemed severable from
            these Terms and will not affect the validity and enforceability of
            any remaining provisions. Our failure to enforce the strict
            performance of any provision of these Terms will not constitute a
            waiver of Our right to subsequently enforce such provision or any
            other provisions of these Terms. You agree that any violation, or
            threatened violation, by You of these Terms constitutes an unlawful
            and unfair business practice that will cause Us irreparable and
            unquantifiable harm. You also agree that monetary damages would be
            inadequate for such harm and consent to Our obtaining any injunctive
            or equitable relief that We deem necessary or appropriate. These
            remedies are in addition to any other remedies We may have at law or
            in equity. We may assign these Terms or any part of them without
            restrictions. These Terms, the Privacy Policy, and other policies We
            may post on the Site constitute the entire agreement between Us and
            You in connection with Your use of the Site and the Content, and
            supersedes any prior agreements between Us and You regarding use of
            the Site, including prior versions of these Terms. The section
            headings are for convenience and do not have any force or effect.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">ENTIRE AGREEMENT</span>. The Terms of
            Use and Our Privacy Policy constitute the sole and entire agreement
            between you and Us regarding the Site and supersede all prior and
            contemporaneous understandings, agreements, representations, and
            warranties, both written and oral, regarding the Site.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">FEEDBACK</span>. This website is
            operated by Asurion, LLC. All other feedback, comments, requests for
            technical support, and other communications relating to the Site
            should be directed to: Legal Department, 140 11th Ave. North,
            Nashville, TN 37203
          </p>
        </div>
      </div>
      <div
        data-testid="spacer"
        className="mx-auto h-16 max-w-screen-2xl bg-white"
      ></div>
    </main>
  );
}
