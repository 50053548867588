import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';
import {
  CopyRight,
  PrivacyPolicy,
  ProductTermsAndConditions,
  TermsOfUse,
} from './components';

const StyledContainer = styled.footer`
  padding: 19px 8px 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  gap: 1rem;
`;

const StyledContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const StyledLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  ${breakpoints.md} {
    justify-content: flex-end;
  }
`;

// Footer props
type FooterProps = PartnerLogoProps & {
  customClickHandler?: (url: string) => void;
  productTermsUrl?: string;
  termsOfUseUrl?: string;
  privacyPolicyUrl?: string;
};

export const Footer = ({
  customClickHandler,
  partner,
  logoHeight,
  productTermsUrl,
  termsOfUseUrl,
  privacyPolicyUrl,
}: FooterProps) => (
  <StyledContainer>
    <PartnerLogo partner={partner} logoHeight={logoHeight} />
    <StyledContentContainer>
      <CopyRight />
      <StyledLinksContainer>
        {productTermsUrl && (
          <ProductTermsAndConditions
            productTermsUrl={productTermsUrl}
            customClickHandler={customClickHandler}
          />
        )}
        {productTermsUrl && termsOfUseUrl && <span>|</span>}
        {termsOfUseUrl && (
          <TermsOfUse
            termsOfUseUrl={termsOfUseUrl}
            customClickHandler={customClickHandler}
          />
        )}
        {termsOfUseUrl && privacyPolicyUrl && <span>|</span>}
        {privacyPolicyUrl && (
          <PrivacyPolicy
            privacyPolicyUrl={privacyPolicyUrl}
            customClickHandler={customClickHandler}
          />
        )}
      </StyledLinksContainer>
    </StyledContentContainer>
  </StyledContainer>
);
