import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeMarketingPageFields {
  cmsDescription?: EntryFieldTypes.Symbol;
  previewUri: EntryFieldTypes.Symbol;
  pageTitle: EntryFieldTypes.Symbol;
  sections: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<EntrySkeletonType>>;
}

export type TypeMarketingPageSkeleton = EntrySkeletonType<
  TypeMarketingPageFields,
  'marketingPage'
>;
export type TypeMarketingPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingPageSkeleton, Modifiers, Locales>;

export function isTypeMarketingPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingPage';
}
