import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCmsComponentCardSkeleton } from './TypeCmsComponentCard';
import type { TypeUtilityComponentVerticalSpacingSkeleton } from './TypeUtilityComponentVerticalSpacing';

export interface TypeMarketingComponentBasicComponentsFields {
  cmsDescription?: EntryFieldTypes.Symbol;
  classes?: EntryFieldTypes.Symbol;
  component: EntryFieldTypes.Symbol<
    | 'AppliancePlusCalculator'
    | 'ApplianceProtectCalculator'
    | 'HomePlusCalculator'
    | 'ImageTextStacked'
    | 'Intro'
  >;
  variant?: EntryFieldTypes.Symbol<
    | 'ImageTextStackedFlexCenter'
    | 'ImageTextStackedFullReview'
    | 'ImageTextStackedGrey'
    | 'ImageTextStackedHorizontal'
  >;
  content: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCmsComponentCardSkeleton>
  >;
  topVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
  bottomVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
}

export type TypeMarketingComponentBasicComponentsSkeleton = EntrySkeletonType<
  TypeMarketingComponentBasicComponentsFields,
  'marketingComponentBasicComponents'
>;
export type TypeMarketingComponentBasicComponents<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingComponentBasicComponentsSkeleton, Modifiers, Locales>;

export function isTypeMarketingComponentBasicComponents<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingComponentBasicComponents<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingComponentBasicComponents';
}
