import { ContentfulLogosComponent } from '@3as-affiliates/shared/types-configs';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { contentfulSpacing, section } from '../functional-css';
import { ResponsiveImage } from '../responsive-image/ResponsiveImage';

export const LogosRatings = styled(LogosRatingsComponent)`
  ${section};
  ${(fields) => contentfulSpacing(fields)}
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Copy = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;

  ${breakpoints.md2} {
    width: auto;
    text-align: right;
    margin-bottom: 0;
  }
`;

const LogoRatingContainer = styled.div`
  display: flex;
  border-right: 0px solid #d5d6da;
  padding-right: 0.5rem;

  ${breakpoints.md} {
    border-width: 1px;
    &:last-child {
      border-width: 0;
      padding-right: 0;
    }
  }
`;

const Image = styled(ResponsiveImage)`
  display: block;
  img {
    height: 74px;
    max-width: 100%;
  }
`;

type TypeEntity = ContentfulLogosComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

// Non-similar Variant Component
function LogosRatingsComponent({ className = '', ...fields }: ComponentProps) {
  console.log({ fields });
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      <Copy>
        <Text weight={'feather'}>{fields.text}</Text>
      </Copy>
      {fields.images.map((image) => {
        if (!image) return null;
        return (
          <LogoRatingContainer key={image.fields.altText}>
            <Image {...image.fields} />
          </LogoRatingContainer>
        );
      })}
    </div>
  );
}
