import { AuthUserProfile, PartnerPersona, personaConfig } from '../config';

export const getUserPersonaPartnerConfig = (
  authUserInfo: AuthUserProfile
): { partner: string | null; persona: PartnerPersona | null } => {
  const authUserPersonas = authUserInfo?.personas;
  if (!authUserPersonas || authUserPersonas.length < 1)
    return { partner: null, persona: null };

  const validPersona = personaConfig
    .filter((persona) =>
      authUserPersonas.some(
        ({ sourcePlatform, entityType, externalId }) =>
          sourcePlatform === persona.sourcePlatform &&
          entityType === persona.entityType &&
          externalId === persona.externalId
      )
    )
    .at(0);

  if (!validPersona) {
    return { partner: null, persona: null };
  } else
    return {
      partner: validPersona.partner,
      persona: validPersona,
    };
};

export const isPersonaAvailable = (authUserInfo: AuthUserProfile): boolean => {
  const partnerPersona = getUserPersonaPartnerConfig(authUserInfo);
  return !!partnerPersona;
};
