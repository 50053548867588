import heroImg from '../assets/remarketing-enrollment/comparion-d2c-hero-2.png';

import socialProofGoogle from '../assets/remarketing-enrollment/socialproof-google@2x.png';
import socialProofTrustPilot from '../assets/remarketing-enrollment/socialproof-trustpilot@2x.png';

import devicesComputer from '../assets/remarketing-enrollment/devices-computer.png';
import devicesGaming from '../assets/remarketing-enrollment/devices-gaming.png';
import devicesHeadphones from '../assets/remarketing-enrollment/devices-headphones.png';
import devicesHomeTheater from '../assets/remarketing-enrollment/devices-home-theater-system.png';
import devicesLaptops from '../assets/remarketing-enrollment/devices-laptops.png';
import devicesTablet from '../assets/remarketing-enrollment/devices-tablets.png';
import devicesTelevision from '../assets/remarketing-enrollment/devices-televisions.png';
import devicesStreaming from '../assets/remarketing-enrollment/devices-video-streaming-device.png';

import { ContentfulMarketingPage } from '@3as-affiliates/shared/types-configs';
import benefitsDeviceProtection from '../assets/remarketing-enrollment/benefits-device-protection@2x.png';
import benefitsLiveSupport from '../assets/remarketing-enrollment/benefits-live-support@2x.png';
import benefitsUnlimitedClaims from '../assets/remarketing-enrollment/benefits-unlimited-claims@2x.png';

export const comparionHomePlusStaticEntry: ContentfulMarketingPage = {
  metadata: { tags: [] },
  sys: {
    space: { sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' } },
    id: '20Z2YmRdd4NupCPuSWFnf5',
    type: 'Entry',
    createdAt: '2024-02-15T19:09:09.912Z',
    updatedAt: '2024-02-19T05:13:31.354Z',
    environment: {
      sys: { id: 'master', type: 'Link', linkType: 'Environment' },
    },
    revision: 2,
    contentType: {
      sys: { type: 'Link', linkType: 'ContentType', id: 'marketingPage' },
    },
    locale: 'en-US',
  },
  fields: {
    previewUri: '/comparion/home-plus/test-24-01-2024',
    pageTitle: 'Comparion Home+ Remarketing Landing Page',
    sections: [
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '6Jre8b0Dx7LmL9puYiiunG',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:09.541Z',
          updatedAt: '2024-02-19T05:11:03.501Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 2,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentHero',
            },
          },
          locale: 'en-US',
        },
        fields: {
          variant: 'HeroVariantTextLeftImageRight',
          headingLevel: 'h1',
          headline:
            "Complete your home's protection with coverage for your home tech.",
          subheading:
            "For less than $1/day, you'll get coverage for drops, spills, breakdowns and more for your home tech - current and future purchases included. And, you'll get unlimited claims with no impact to your homeowner's premium.",
          cta: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '18nxXiKZKjEEtzo2TodhFo',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:50.986Z',
                updatedAt: '2024-02-15T19:08:50.986Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentLink',
                  },
                },
                locale: 'en-US',
              },
              fields: { text: 'Sign up now' },
            },
          ],
          image: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '58UU2CgnoeUX9mmVxFcgXM',
              type: 'Entry',
              createdAt: '2024-02-15T19:08:51.374Z',
              updatedAt: '2024-02-19T05:09:49.263Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 3,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'cmsComponentResponsiveImage',
                },
              },
              locale: 'en-US',
            },
            fields: {
              altText: 'A collection of devices',
              default: {
                metadata: { tags: [] },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'mctkccx1dzar',
                    },
                  },
                  id: '5h7rHF8OAxIpM8HbKFF7vj',
                  type: 'Asset',
                  createdAt: '2024-02-19T05:09:32.501Z',
                  updatedAt: '2024-02-19T05:09:32.501Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  revision: 1,
                  locale: 'en-US',
                },
                fields: {
                  title: 'comparion-d2c-hero-2',
                  description: '',
                  file: {
                    url: heroImg,
                    details: {
                      size: 125808,
                      image: { width: 1264, height: 882 },
                    },
                    fileName: heroImg,
                    contentType: 'image/png',
                  },
                },
              },
            },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: 'JLyhQQYBFEEgCLK4t8Ub1',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:08.778Z',
          updatedAt: '2024-02-15T19:09:08.778Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentLogos',
            },
          },
          locale: 'en-US',
        },
        fields: {
          variant: 'LogosRatings',
          text: 'Asurion is trusted by 300 million worldwide.',
          images: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '3E9ypfrJRuy2LLQ3WX5ksO',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:52.712Z',
                updatedAt: '2024-02-15T19:08:52.712Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: '4.6 Google rating',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '4SAFEjeskMv5dcBQSQsNtk',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:38.216Z',
                    updatedAt: '2024-02-15T19:08:38.216Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'socialproof-google@2x',
                    description: '4.6 Google rating',
                    file: {
                      url: socialProofGoogle,
                      details: {
                        size: 3932,
                        image: { width: 568, height: 149 },
                      },
                      fileName: socialProofGoogle,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '1QyAccLRcAcoDoJYlvip49',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:53.082Z',
                updatedAt: '2024-02-15T19:08:53.082Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: '4.5 Trustpilot rating',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '3KtdWNB7DhPvjFfZvjSUU1',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:38.496Z',
                    updatedAt: '2024-02-15T19:08:38.496Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: '4.5 Trustpilot rating',
                    description: '',
                    file: {
                      url: socialProofTrustPilot,
                      details: {
                        size: 2697,
                        image: { width: 568, height: 149 },
                      },
                      fileName: socialProofTrustPilot,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
          ],
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '3idnQR5uxpJSf03yiL15vt',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:00.419Z',
              updatedAt: '2024-02-15T19:09:00.419Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '20/40', defaultPx: 20, md2Px: 40 },
          },
          bottomVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '3xeMpo6uXALOW0yFB5noUf',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:00.730Z',
              updatedAt: '2024-02-15T19:09:00.730Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '20/40', defaultPx: 20, md2Px: 40 },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '2qD2LckRscYMO4R6Qec7Je',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:09.150Z',
          updatedAt: '2024-02-15T19:09:09.150Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentFeature',
            },
          },
          locale: 'en-US',
        },
        fields: {
          variant: 'FeatureVariantYoutubeLeftTextRight',
          headline: 'We do tech care differently',
          subheading:
            "The gadgets we love aren't cheap, but they can be protected for less than $1 per day with Asurion Home+®.",
          list: [
            'Thousands of devices covered (future purchases too)',
            'Simple claims, with or without a receipt',
            '24/7 live expert support and data monitoring',
          ],
          cta: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '2WqJ0tLsKXyHyRn4JnDTxE',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:52.374Z',
                updatedAt: '2024-02-15T19:08:52.374Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentLink',
                  },
                },
                locale: 'en-US',
              },
              fields: { text: 'Sign up now' },
            },
          ],
          youtubeId: '_D5A3gFVMdk',
          autoplay: false,
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '1vQzeFUfSICF4V5Oj6xm9a',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:01.035Z',
              updatedAt: '2024-02-15T19:09:01.035Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '40/80', defaultPx: 40, md2Px: 80 },
          },
          bottomVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '0aqTuyK9EwNqj4OfHLM9s',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:01.329Z',
              updatedAt: '2024-02-15T19:09:01.329Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '40/80', defaultPx: 40, md2Px: 80 },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '24DjUU5v29oH1bqZ6Gaeeg',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:08.160Z',
          updatedAt: '2024-02-15T19:09:08.160Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentBasicComponents',
            },
          },
          locale: 'en-US',
        },
        fields: {
          component: 'Intro',
          content: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '6T2p43sOjelvWn79csqfsc',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:54.000Z',
                updatedAt: '2024-02-15T19:08:54.000Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentCard',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                headline: 'We cover thousands of devices in your home',
                headingLevel: 'h2',
                subheading:
                  'An Asurion Home+ plan covers the following devices and many more. Select a device to find out about its coverage. Asurion Home+ does not cover mobile devices. Limitations and exclusions apply. Please see the [Asurion Home+ Terms and Conditions](https://www.asurion.com/pdf/homeplus-terms-conditions-080123/) for complete details.',
              },
            },
          ],
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '1dUNuOpbp0Xm2IbZP3Nu1f',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:02.240Z',
              updatedAt: '2024-02-15T19:09:02.240Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '28/76', defaultPx: 28, md2Px: 76 },
          },
          bottomVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '5uqfTYcem1zykGJSnRklzv',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:02.544Z',
              updatedAt: '2024-02-15T19:09:02.544Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '28/76', defaultPx: 28, md2Px: 76 },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '6TneNkHYGFo3K7Pf91od2V',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:07.700Z',
          updatedAt: '2024-02-15T19:09:07.700Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentLogos',
            },
          },
          locale: 'en-US',
        },
        fields: {
          variant: 'LogosLarge',
          images: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '5ZmTgeM3HTEk4hqWCqJsRx',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:54.589Z',
                updatedAt: '2024-02-15T19:08:54.589Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Televisions',
                label: 'Televisions',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '6XcTIPjgRVgLqhlXPRvfuo',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:38.834Z',
                    updatedAt: '2024-02-15T19:08:38.834Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Television',
                    description: '',
                    file: {
                      url: devicesTelevision,
                      details: {
                        size: 14626,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesTelevision,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '2zWDmZEnbWpsL5uDZtv6Dt',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:54.936Z',
                updatedAt: '2024-02-15T19:08:54.936Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Laptops',
                label: 'Laptops',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '1TpcmPxd1F7e8xYb9Hs9vw',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:39.124Z',
                    updatedAt: '2024-02-15T19:08:39.124Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Laptop',
                    description: '',
                    file: {
                      url: devicesLaptops,
                      details: {
                        size: 13997,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesLaptops,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '2UHyTo5D4pHbWT1PN4MpEh',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:55.267Z',
                updatedAt: '2024-02-15T19:08:55.267Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Desktop PCs',
                label: 'Desktop PCs',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '5fMoxXxERXH03hCSx89sQp',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:39.412Z',
                    updatedAt: '2024-02-15T19:08:39.412Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Desktop PC',
                    description: '',
                    file: {
                      url: devicesComputer,
                      details: {
                        size: 13870,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesComputer,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: 'vB4Z804CqoLewb7u1sGNi',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:55.657Z',
                updatedAt: '2024-02-15T19:08:55.657Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Tablets',
                label: 'Tablets',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '4yCq4nFM1rPGYphXsJy0eW',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:39.693Z',
                    updatedAt: '2024-02-15T19:08:39.693Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Tablet',
                    description: '',
                    file: {
                      url: devicesTablet,
                      details: {
                        size: 10761,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesTablet,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '3v8VDUVrbl0Z95fFZZxuUF',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:56.014Z',
                updatedAt: '2024-02-15T19:08:56.014Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Gaming Consoles',
                label: 'Gaming Consoles',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '4gx2JYl2q7nB5p6TaIzasm',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:39.949Z',
                    updatedAt: '2024-02-15T19:08:39.949Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Gaming Console',
                    description: '',
                    file: {
                      url: devicesGaming,
                      details: {
                        size: 9480,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesGaming,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: 'zbQvOAg1t1kHTAJKUJ3fy',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:56.334Z',
                updatedAt: '2024-02-15T19:08:56.334Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Streaming Devices',
                label: 'Streaming Devices',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '1FKVxZEoTG5PTETTfvRNq5',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:40.219Z',
                    updatedAt: '2024-02-15T19:08:40.219Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Streaming Device',
                    description: '',
                    file: {
                      url: devicesStreaming,
                      details: {
                        size: 3535,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesStreaming,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '5bZuSK8iziI5SD9Y9dAkQm',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:56.679Z',
                updatedAt: '2024-02-15T19:08:56.679Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Home Theater Systems',
                label: 'Home Theater Systems',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '6J2hfGLTmyAdBr3wyaLEnL',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:40.500Z',
                    updatedAt: '2024-02-15T19:08:40.500Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Home Theater System',
                    description: '',
                    file: {
                      url: devicesHomeTheater,
                      details: {
                        size: 6128,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesHomeTheater,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '2axoVM2wSINsjooogpaXNd',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:56.998Z',
                updatedAt: '2024-02-15T19:08:56.998Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentResponsiveImage',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                altText: 'Audio Headphones',
                label: 'Audio Headphones',
                default: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '1qeyYEet3Ax0Q6fyLU1TNM',
                    type: 'Asset',
                    createdAt: '2024-02-15T19:08:40.746Z',
                    updatedAt: '2024-02-15T19:08:40.746Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Audio Headphones',
                    description: '',
                    file: {
                      url: devicesHeadphones,
                      details: {
                        size: 6157,
                        image: { width: 368, height: 212 },
                      },
                      fileName: devicesHeadphones,
                      contentType: 'image/png',
                    },
                  },
                },
              },
            },
          ],
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '01xmtFlZyC1DDyljkKKjuU',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:02.885Z',
              updatedAt: '2024-02-15T19:09:02.885Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '24/48', defaultPx: 24, md2Px: 48 },
          },
          bottomVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '2zqDEcaJ7kUeMuokUZUwho',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:03.206Z',
              updatedAt: '2024-02-15T19:09:03.206Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '24/48', defaultPx: 24, md2Px: 48 },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '5fL9KLwkEN0gU0z8OYdlfL',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:07.284Z',
          updatedAt: '2024-02-15T19:09:07.284Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentBasicComponents',
            },
          },
          locale: 'en-US',
        },
        fields: {
          component: 'Intro',
          content: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '2GLGVgG1movIfmzzrUqn2N',
                type: 'Entry',
                createdAt: '2024-02-15T19:08:57.663Z',
                updatedAt: '2024-02-15T19:08:57.663Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentCard',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                headline: 'One simple plan, tons of amazing benefits',
                headingLevel: 'h2',
              },
            },
          ],
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '2snyD39GE9xzbrayvxc2uy',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:03.514Z',
              updatedAt: '2024-02-15T19:09:03.514Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '32/72', defaultPx: 32, md2Px: 72 },
          },
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
          },
          id: '4Aioa17daKsFcm82KkYnYB',
          type: 'Entry',
          createdAt: '2024-02-15T19:09:06.904Z',
          updatedAt: '2024-02-15T19:09:06.904Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'marketingComponentBasicComponents',
            },
          },
          locale: 'en-US',
        },
        fields: {
          component: 'ImageTextStacked',
          content: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '5d5Tl9n82mQL3OSkv1jclm',
                type: 'Entry',
                createdAt: '2024-02-15T19:09:06.521Z',
                updatedAt: '2024-02-15T19:09:06.521Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentCard',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                headline: 'Live support by experts',
                headingLevel: 'h3',
                subheading:
                  'Have a tech question? Our Asurion Experts are ready to help. Just call or chat with us anytime. Need help installing your home tech? We can do that too.',
                image: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '2odqhEOWzPvRrgOu4sWSw5',
                    type: 'Entry',
                    createdAt: '2024-02-15T19:08:57.974Z',
                    updatedAt: '2024-02-15T19:08:57.974Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    contentType: {
                      sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'cmsComponentResponsiveImage',
                      },
                    },
                    locale: 'en-US',
                  },
                  fields: {
                    altText:
                      'Get live support from a 5-star Asurion expert for all your tech questions',
                    default: {
                      metadata: { tags: [] },
                      sys: {
                        space: {
                          sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 'mctkccx1dzar',
                          },
                        },
                        id: 'n2EREvY0tTZXRYAEavYoj',
                        type: 'Asset',
                        createdAt: '2024-02-15T19:08:41.010Z',
                        updatedAt: '2024-02-15T19:08:41.010Z',
                        environment: {
                          sys: {
                            id: 'master',
                            type: 'Link',
                            linkType: 'Environment',
                          },
                        },
                        revision: 1,
                        locale: 'en-US',
                      },
                      fields: {
                        title: 'benefits-live-support@2x',
                        description:
                          'Get live support from a 5-star Asurion expert for all your tech questions',
                        file: {
                          url: benefitsLiveSupport,
                          details: {
                            size: 59637,
                            image: { width: 768, height: 604 },
                          },
                          fileName: benefitsLiveSupport,
                          contentType: 'image/png',
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '1Ikf4g2FwUYbEy56q4JDdE',
                type: 'Entry',
                createdAt: '2024-02-15T19:09:06.172Z',
                updatedAt: '2024-02-15T19:09:06.172Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentCard',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                headline: 'Unlimited claims',
                headingLevel: 'h3',
                subheading:
                  "Unlimited claims with no impact to your homeowner's premium.",
                image: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '75I1zKZJVzlfDQo4BORFmQ',
                    type: 'Entry',
                    createdAt: '2024-02-15T19:08:58.300Z',
                    updatedAt: '2024-02-15T19:08:58.300Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    contentType: {
                      sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'cmsComponentResponsiveImage',
                      },
                    },
                    locale: 'en-US',
                  },
                  fields: {
                    altText: 'a smartwatch with a parachute',
                    default: {
                      metadata: { tags: [] },
                      sys: {
                        space: {
                          sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 'mctkccx1dzar',
                          },
                        },
                        id: '1wNoYcYBveIbDqsW7f7VSS',
                        type: 'Asset',
                        createdAt: '2024-02-15T19:08:41.270Z',
                        updatedAt: '2024-02-15T19:08:41.270Z',
                        environment: {
                          sys: {
                            id: 'master',
                            type: 'Link',
                            linkType: 'Environment',
                          },
                        },
                        revision: 1,
                        locale: 'en-US',
                      },
                      fields: {
                        title: 'benefits-unlimited-claims@2x',
                        description: 'a smartwatch with a parachute',
                        file: {
                          url: benefitsUnlimitedClaims,
                          details: {
                            size: 35155,
                            image: { width: 768, height: 605 },
                          },
                          fileName: benefitsUnlimitedClaims,
                          contentType: 'image/png',
                        },
                      },
                    },
                  },
                },
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
                },
                id: '3qTlJD5dlATy30uK7FMOSc',
                type: 'Entry',
                createdAt: '2024-02-15T19:09:05.844Z',
                updatedAt: '2024-02-15T19:09:05.844Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'cmsComponentCard',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                headline: 'Device protection',
                headingLevel: 'h3',
                subheading:
                  "We cover thousands of devices in your home—from TVs and tablets to smart thermostats and locks. If we can't repair it, we'll replace it.",
                image: {
                  metadata: { tags: [] },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'mctkccx1dzar',
                      },
                    },
                    id: '1xq46EpDjQEzU8vtJaT88o',
                    type: 'Entry',
                    createdAt: '2024-02-15T19:08:58.648Z',
                    updatedAt: '2024-02-15T19:08:58.648Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 1,
                    contentType: {
                      sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'cmsComponentResponsiveImage',
                      },
                    },
                    locale: 'en-US',
                  },
                  fields: {
                    altText:
                      'A collection of devices covered under device protection',
                    default: {
                      metadata: { tags: [] },
                      sys: {
                        space: {
                          sys: {
                            type: 'Link',
                            linkType: 'Space',
                            id: 'mctkccx1dzar',
                          },
                        },
                        id: '3CdJ8chZdxsnrlbtEU7eQ4',
                        type: 'Asset',
                        createdAt: '2024-02-15T19:08:41.520Z',
                        updatedAt: '2024-02-15T19:08:41.520Z',
                        environment: {
                          sys: {
                            id: 'master',
                            type: 'Link',
                            linkType: 'Environment',
                          },
                        },
                        revision: 1,
                        locale: 'en-US',
                      },
                      fields: {
                        title: 'benefits-device-protection@2x',
                        description:
                          'A collection of devices covered under device protection',
                        file: {
                          url: benefitsDeviceProtection,
                          details: {
                            size: 28804,
                            image: { width: 768, height: 605 },
                          },
                          fileName: benefitsDeviceProtection,
                          contentType: 'image/png',
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
          topVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '77KpJmK9lqRcAEyAwI5NW1',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:03.838Z',
              updatedAt: '2024-02-15T19:09:03.838Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '28/60', defaultPx: 28, mdPx: 28, md2Px: 60 },
          },
          bottomVerticalSpacing: {
            metadata: { tags: [] },
            sys: {
              space: {
                sys: { type: 'Link', linkType: 'Space', id: 'mctkccx1dzar' },
              },
              id: '45heaCQVg66aylZHyWkMhp',
              type: 'Entry',
              createdAt: '2024-02-15T19:09:04.162Z',
              updatedAt: '2024-02-15T19:09:04.162Z',
              environment: {
                sys: { id: 'master', type: 'Link', linkType: 'Environment' },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'utilityComponentVerticalSpacing',
                },
              },
              locale: 'en-US',
            },
            fields: { name: '28/60', defaultPx: 28, md2Px: 60 },
          },
        },
      },
    ],
  },
};
