import {
  PageContainerWithViewAnalyticsHeaderFooter,
  PitchAssist,
  ShareLink,
  TrainingEducation,
  TypeProductContentConfig,
} from '@3as-affiliates/partners/web/components';
import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import {
  AffiliationType,
  OrganizationIds,
  OrganizationInvertedKeys,
  ProductSlugs,
  ProductSlugsInvertedKeys,
  SupportedPartners,
  SupportedProducts,
} from '@3as-affiliates/shared/types-configs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@3as-affiliates/shared/web/utils/auth-provider';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import { useParams } from 'react-router-dom';
import { trainingMaterialsConfig } from '../../partner-data/content';
import { comparionApplianceProtectProductContent } from '../../partner-data/content/comparion/appliance-protect';
import { viuApplianceProtectProductContent } from '../../partner-data/content/viu/appliance-protect';
import { viuHomePlusProductContent } from '../../partner-data/content/viu/home-plus';
import { NotFound } from '../NotFound';
import { Unauthorized } from '../Unauthorized';
import {
  Banner,
  BannerContainer,
  BannerImage,
  ProductPitchActionContainer,
  ProductPitchActionDecline,
  ProductPitchActionStart,
  ProductPitchAssistContainer,
  SalesProductPageContainer,
  ShareLinkContainer,
  Tab,
  TabContent,
  TabGroup,
  TabImage,
  TabbedContentContainer,
  TrainingEducationContainer,
  TrainingEducationGrid,
  TrainingEducationTitle,
  WelcomeMessage,
} from './product-styles';

export const SalesPageContentConfig: TypeProductContentConfigMap = {
  [OrganizationIds[SupportedPartners.Comparion]]: {
    // [SupportedProducts.HomePlus]: comparionHomePlusProductContent,
    [SupportedProducts.ApplianceProtect]:
      comparionApplianceProtectProductContent,
  },
  [OrganizationIds[SupportedPartners.VIU]]: {
    [SupportedProducts.ApplianceProtect]: viuApplianceProtectProductContent,
    [SupportedProducts.HomePlus]: viuHomePlusProductContent,
  },
};

type TypeProductContentConfigMap = {
  [key: string]: {
    [key: string]: TypeProductContentConfig;
  };
};

const FEATURE_FLAG = {
  referralLink: process.env['NX_REFERRAL_LINK_ENABLED'],
};

const d2cBaseUrl = process.env['NX_D2C_BASE_URL'];

export function SalesProductPage() {
  const params = useParams();
  const { affiliateInfo } = useAppData();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { getAuthUserInfo } = useAuth();

  // Users Affiliations
  const affiliations = affiliateInfo?.affiliations as
    | AffiliationType[]
    | undefined;

  // Routed or Default Affiliation
  const affiliation = affiliations?.at(0);

  // Org + Product + Content
  const orgId = affiliation?.organizationId || '';
  const orgName = OrganizationInvertedKeys[orgId];
  const orgConfig = SalesPageContentConfig[orgId];

  const productDefaultId = Object.keys(orgConfig || {})[0];
  const productDefaultSlug = ProductSlugs?.[productDefaultId];

  const productSlug = params?.productSlug || productDefaultSlug;
  const productKey = ProductSlugsInvertedKeys?.[productSlug];
  const content = orgConfig?.[productKey];

  const productName = content?.name;
  const affiliateFirstName = affiliateInfo?.firstName;

  let referralLink;

  if (productKey === SupportedProducts.HomePlus)
    referralLink =
      affiliation?.referralLinkHomePlus || affiliation?.referralLink;
  if (productKey === SupportedProducts.ApplianceProtect)
    referralLink = affiliation?.referralLinkApplianceProtect;

  const declineRoute =
    '/home/' + Routes.SalesProductDecline.replace(':productSlug', productSlug);

  // Destructure Org Products into UI data
  type TabBanner = { isActive: boolean; image: { url: string; alt: string } };
  type Tab = { link: string } & TabBanner;
  const tabs: Tab[] = [];
  const banners: TabBanner[] = [];

  Object.keys(orgConfig || {}).forEach((productKey) => {
    const product = orgConfig[productKey];
    const slug = ProductSlugs[productKey];
    const isActive = slug === productSlug;
    const link = '/home/' + Routes.SalesProduct.replace(':productSlug', slug);
    tabs.push({ isActive, image: product.tabImage, link });
    banners.push({ isActive, image: product.bannerImage });
  });

  // Training Materials
  const trainingMaterials = trainingMaterialsConfig.find(
    ({ company }) => company === orgName
  );

  // Event Handlers
  async function handleStartSale(e: { preventDefault: () => void }) {
    e.preventDefault();
    const authUserInfo = await getAuthUserInfo();
    const accessToken = authUserInfo.accessToken;
    const enrollmentForm = document.createElement('form');
    enrollmentForm.method = 'post';
    enrollmentForm.action = `${d2cBaseUrl}${content.d2cUri}?a_id=${affiliation?.externalId}`;
    const authField = document.createElement('input');
    authField.name = 'Authorization';
    authField.value = accessToken;
    enrollmentForm.appendChild(authField);
    document.body.appendChild(enrollmentForm);
    enrollmentForm.submit();
  }

  function dispatchTrainingClick({
    label,
    url,
  }: {
    label: string;
    url: string;
  }) {
    dispatchClickAnalytics(
      {
        Cta: 'TrainingMaterialButton',
        ActionId: 'LaunchTrainingMaterial',
      },
      {
        Provider: orgName,
        Material: label,
        Link: url,
      }
    );
  }

  if (!affiliation) {
    return <Unauthorized />;
  }

  if (!orgConfig || !affiliation?.externalId || !content)
    return (
      <NotFound
        pageName={`${PageNames.HomePage} | Partner: ${orgName} | Product: ${productSlug}`}
      />
    );

  return (
    <SalesProductPageContainer>
      <PageContainerWithViewAnalyticsHeaderFooter pageName={PageNames.HomePage}>
        <BannerContainer>
          {banners.map((banner) => (
            <Banner
              key={banner.image.url}
              className={banner.isActive ? 'active' : 'inactive'}
            >
              <BannerImage src={banner.image.url} alt={banner.image.alt} />
            </Banner>
          ))}
        </BannerContainer>

        <WelcomeMessage forwardedAs={'h1'} size={6} weight={'feather'}>
          {affiliateFirstName ? `Welcome, ${affiliateFirstName}!` : `Welcome!`}
        </WelcomeMessage>

        <TabbedContentContainer>
          {tabs.length > 1 && (
            <TabGroup>
              {tabs.map((tab) => (
                <Tab
                  key={tab.image.url}
                  to={tab.link}
                  className={tab.isActive ? 'active' : 'inactive'}
                >
                  <TabImage src={tab.image.url} alt={tab.image.alt} />
                </Tab>
              ))}
            </TabGroup>
          )}

          <TabContent>
            {FEATURE_FLAG.referralLink && productName && referralLink && (
              <ShareLinkContainer>
                <ShareLink
                  title={`YOUR ${productName.toUpperCase()} SHARE LINK`}
                  link={referralLink}
                  url={referralLink}
                />
              </ShareLinkContainer>
            )}
            <ProductPitchActionContainer>
              <ProductPitchActionStart onClick={handleStartSale}>
                <Text size={4}>Start a {productName} sale</Text>
              </ProductPitchActionStart>
              <ProductPitchActionDecline to={declineRoute}>
                <Text size={4}>Submit a {productName} decline</Text>
              </ProductPitchActionDecline>
            </ProductPitchActionContainer>
            <ProductPitchAssistContainer>
              <PitchAssist config={content.pitchAssist} />
            </ProductPitchAssistContainer>
            {trainingMaterials && trainingMaterials.content && (
              <TrainingEducationContainer>
                <TrainingEducationTitle
                  forwardedAs={'h2'}
                  size={5}
                  weight={'feather'}
                >
                  Training + education ({trainingMaterials.content.length})
                </TrainingEducationTitle>
                <TrainingEducationGrid minWidth={12}>
                  {trainingMaterials.content.map((item) => (
                    <TrainingEducation
                      key={item.url}
                      url={item.url}
                      label={item.label}
                      onClick={() => dispatchTrainingClick(item)}
                    />
                  ))}
                </TrainingEducationGrid>
              </TrainingEducationContainer>
            )}
          </TabContent>
        </TabbedContentContainer>
      </PageContainerWithViewAnalyticsHeaderFooter>
    </SalesProductPageContainer>
  );
}
