import { ContentfulFeatureComponent } from '@3as-affiliates/shared/types-configs';
import {
  breakpoints,
  ButtonGroup,
  Link,
  LinkProps,
  Text,
} from '@soluto-private/mx-asurion-ui-react';
import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';
import { contentfulSpacing, section } from '../functional-css';
import { VimeoPlayer, YoutubePlayer } from '../video-player';
import CheckImg from './check.svg';

const FeatureYoutube = styled(FeatureVideoComponent)`
  ${section};
  ${(fields) => contentfulSpacing(fields)}

  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1rem;

  ${breakpoints.md} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  ${breakpoints.md2} {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3.12rem;
  }
`;
const MediaContainer = styled.div`
  grid-column: span 6 / span 6;

  ${breakpoints.md} {
    grid-column: span 12 / span 12;
  }

  ${breakpoints.md2} {
    grid-column: span 1 / span 1;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-column: span 6 / span 6;
  padding: 2rem 1.5rem;
  gap: 1rem;

  ${breakpoints.md} {
    padding: 2rem 0;
    grid-column: span 8 / span 8;
    grid-column-start: 3;
  }

  ${breakpoints.md2} {
    padding: 0;
    max-width: 31.9rem;
    grid-column: span 1 / span 1;
    grid-column-start: 0;
  }
`;
const Headline = styled(Text)``;
const Subheading = styled(Text)`
  p {
    margin: 0;
    white-space: break-spaces;
  }
`;

const UnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 0;
  margin: 0;
`;
const ListItem = styled.li`
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;

  &:before {
    background-color: #8223d2;
    content: '';
    display: block;
    height: 22px;
    left: 0;
    mask-image: url(${CheckImg});
    position: absolute;
    width: 24px;
  }
`;

export const FeatureVariantYoutubeLeftTextRight = styled(FeatureYoutube)`
  ${MediaContainer} {
    padding: 0.5rem;
  }
`;

export const FeatureVariantVimeoLeftTextRight = styled(
  FeatureVariantYoutubeLeftTextRight
)``;

type TypeEntity = ContentfulFeatureComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;
type FeatureComponentProps = ComponentProps;

// Dynamic Component
// Base Component w/ Variants
function FeatureVideoComponent({
  className = '',
  autoplay = false,
  ...fields
}: FeatureComponentProps) {
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      <MediaContainer>
        {fields.vimeoUrl && fields.poster?.fields.file?.url && (
          <VimeoPlayer
            vimeoUrl={fields.vimeoUrl}
            poster={fields.poster.fields.file.url}
            autoplay={autoplay}
          />
        )}

        {fields.youtubeId && !fields.vimeoUrl && (
          <YoutubePlayer
            id={fields.youtubeId}
            autoplay={false}
            poster={fields.poster?.fields.file?.url}
          />
        )}
      </MediaContainer>
      <TextContainer>
        <Headline forwardedAs={'h2'} weight={'feather'} size={6}>
          {fields.headline}
        </Headline>
        <Subheading forwardedAs={'span'} weight={'feather'} size={3}>
          <Markdown>{fields.subheading}</Markdown>
        </Subheading>
        <UnorderedList>
          {fields.list &&
            fields.list.map((item) => (
              <ListItem key={item}>
                <Text weight={'feather'} size={3}>
                  {item}
                </Text>
              </ListItem>
            ))}
        </UnorderedList>
        {fields.cta && fields.cta[0] && (
          <ButtonGroup>
            <Link
              variant={'button'}
              btnVariantProps={
                {
                  btnSize: 'large',
                  btnVariant: 'outline',
                } as LinkProps['btnVariantProps']
              }
              href={fields.cta[0].fields.url}
            >
              {fields.cta[0].fields.text}
            </Link>
          </ButtonGroup>
        )}
      </TextContainer>
    </div>
  );
}
