import { ContentfulResponsiveImageComponent } from '@3as-affiliates/shared/types-configs';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';

type TypeEntity = ContentfulResponsiveImageComponent;
type TypeFields = TypeEntity['fields'];

function formatBP(breakpoint: string) {
  return breakpoint.replace('@media ', '');
}

export type ResponsiveImageProps = { className?: string } & TypeFields;
export function ResponsiveImage({
  className = '',
  ...fields
}: ResponsiveImageProps) {
  if (!fields.default?.fields?.file) return null;

  const sources = [];
  if (fields.md?.fields?.file)
    sources.push({
      media: formatBP(breakpoints.md),
      srcSet: fields.md.fields.file.url,
    });
  if (fields.md2?.fields?.file)
    sources.push({
      media: formatBP(breakpoints.md2),
      srcSet: fields.md2.fields.file.url,
    });
  if (fields.lg?.fields?.file)
    sources.push({
      media: formatBP(breakpoints.lg),
      srcSet: fields.lg.fields.file.url,
    });
  if (fields.xl?.fields?.file)
    sources.push({
      media: formatBP(breakpoints.xl),
      srcSet: fields.xl.fields.file.url,
    });
  if (fields.xxl?.fields?.file)
    sources.push({
      media: formatBP(breakpoints.xxl),
      srcSet: fields.xxl.fields.file.url,
    });

  return (
    <picture className={className}>
      {sources.map((attr) => (
        <source key={attr.srcSet} {...attr} />
      ))}
      <img alt={fields.altText} src={fields.default.fields.file.url} />
    </picture>
  );
}
