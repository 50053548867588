//  ToDo: with affiliates app disabling: remove ChatBotRequest config if chat api is not required
export enum CHAT_ROLES {
  ASSISTANT = 'Assistant',
  HUMAN = 'Human',
}

export interface ChatMessage {
  role: CHAT_ROLES;
  content: string;
}

export type ChatContextMessages = Array<ChatMessage>;

export interface ChatBotRequest {
  asurionId: string;
  sessionId: string;
  messages: ChatContextMessages;
}
export interface AffiliateType {
  asurionId: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName?: string;
  role?: string;
  employeeId?: string;
  affiliations?: AffiliationType[];
}

export interface AffiliationType {
  asurionId: string;
  campaignId: string;
  cohortId: string;
  organizationId: string;
  externalId?: string;
  referralLink?: string; // Deprecated, use "referralLinkHomePlus"
  referralLinkHomePlus?: string;
  referralLinkApplianceProtect?: string;
  referralLinkApplianceProtectGift?: string;
}
