import { GlobalStyle } from '@3as-affiliates/partners/web/styles';
import { AnalyticsProvider } from '@3as-affiliates/shared/web/utils/analytics';
import { ContentfulProvider } from '@3as-affiliates/shared/web/utils/contentful';
import { RouterProvider } from 'react-router-dom';
import { appAnalyticsConfig } from '../initializations';
import { router } from '../router';

const App = () => {
  return (
    <AnalyticsProvider appAnalyticsConfig={appAnalyticsConfig}>
      <GlobalStyle />
      <ContentfulProvider>
        <RouterProvider router={router} />
      </ContentfulProvider>
    </AnalyticsProvider>
  );
};

export default App;
