import { ContentfulSpacing } from '@3as-affiliates/shared/types-configs';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { css } from 'styled-components';

export const documentMargin = {
  // xs: '1.5rem',
  sm: '1.5rem',
  md: '1.5rem',
  md2: '3rem',
  // lg: '3rem',
  // xl: '3rem',
  // xxl: '3rem'
};

export function px(num: number | undefined) {
  if (num) return `${num}px`;
  return undefined;
}

export const contentfulSpacing = (fields: ContentfulSpacing) => css`
  padding-top: ${px(fields?.topVerticalSpacing?.fields?.defaultPx)};
  padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.defaultPx)};

  ${breakpoints.md} {
    padding-top: ${px(fields?.topVerticalSpacing?.fields?.mdPx)};
    padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.mdPx)};
  }

  ${breakpoints.md2} {
    padding-top: ${px(fields?.topVerticalSpacing?.fields?.md2Px)};
    padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.md2Px)};
  }

  ${breakpoints.lg} {
    padding-top: ${px(fields?.topVerticalSpacing?.fields?.lgPx)};
    padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.lgPx)};
  }

  ${breakpoints.xl} {
    padding-top: ${px(fields?.topVerticalSpacing?.fields?.xlPx)};
    padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.xlPx)};
  }

  ${breakpoints.xxl} {
    padding-top: ${px(fields?.topVerticalSpacing?.fields?.xxlPx)};
    padding-bottom: ${px(fields?.bottomVerticalSpacing?.fields?.xxlPx)};
  }
`;

export const section = css`
  max-width: 100%;
  padding-left: ${documentMargin.sm};
  padding-right: ${documentMargin.sm};

  ${breakpoints.md2} {
    padding-left: ${documentMargin.md2};
    padding-right: ${documentMargin.md2};
  }
`;

export const halfColumnMD2 = css`
  grid-column: span 6 / span 6;

  ${breakpoints.md} {
    grid-column: span 12 / span 12;
  }

  ${breakpoints.md2} {
    grid-column: span 6 / span 6;
  }
`;

export const grid = css`
  ${section};
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1rem;

  ${breakpoints.md} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`;
