import {
  FeatureItem,
  PageContainerWithViewAnalyticsHeaderFooter,
  VerticalSpacer,
  WelcomeBanner,
} from '@3as-affiliates/partners/web/components';
import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { Button, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { featuresConfig } from '../partner-data/content';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: auto;
`;

const StyledTitle = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${colors.black};
  font-size: 40px;
  font-weight: 300;
  line-height: 115%;
  margin: 0;
`;

const StyledSubtitle = styled.p`
  display: flex;
  justify-content: center;
  width: 500px;
  color: ${colors.black};
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
`;

const StyledDescription = styled.p`
  display: flex;
  justify-content: center;
  color: ${colors.black};
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
`;

const StyledButton = styled(Button)`
  width: 150px !important;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LandingPage = () => {
  const { logIn, authState } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const navigate = useNavigate();

  // Handling unregistered wild card browser URL
  useEffect(() => {
    if (authState === AuthenticationState.LoggedIn) {
      navigate(Routes.LoggedInHome, { replace: true });
    }
  }, [navigate, authState]);

  const onLoginButtonClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Sign in',
        ActionId: 'signInButton',
      },
      {
        PageName: PageNames.LandingPage,
      }
    );
    logIn();
  };

  return (
    <PageContainerWithViewAnalyticsHeaderFooter
      pageName={PageNames.LandingPage}
    >
      <Container>
        <VerticalSpacer />
        <WelcomeBanner />
        <VerticalSpacer />
        <StyledTitle>{'Welcome!'}</StyledTitle>
        <VerticalSpacer />
        <StyledSubtitle>
          {
            'Log in to your account to access your personalized affiliate dashboard and start selling.'
          }
        </StyledSubtitle>
        <VerticalSpacer value={2} />
        <StyledButton onClick={onLoginButtonClick}>{'Sign In'}</StyledButton>
        <VerticalSpacer value={2} />
        <StyledDescription>{'Your one-stop-shop for'}</StyledDescription>
        <VerticalSpacer value={2} />
        <FeaturesWrapper>
          {featuresConfig.map((feature, index) => {
            return (
              <FeatureItem
                key={index}
                src={feature.image.src}
                alt={feature.image.alt}
                title={feature.title}
                subtitle={feature.subtitle}
              />
            );
          })}
        </FeaturesWrapper>
        <VerticalSpacer />
      </Container>
    </PageContainerWithViewAnalyticsHeaderFooter>
  );
};
