import { LegalCopyTailwindsSubset } from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import { PageContainerWithViewAnalytics } from '@3as-affiliates/shared/web/components';

/*
 * LEGAL COPY LAST COPIED FROM https://www.asurion.com/privacy-notice/ ON JANUARY 15TH, 2024
 */

export const PrivacyNotice = () => {
  return (
    <LegalCopyTailwindsSubset>
      <PageContainerWithViewAnalytics pageName={PageNames.PrivacyNotice}>
        <Content />
      </PageContainerWithViewAnalytics>
    </LegalCopyTailwindsSubset>
  );
};

function Content() {
  return (
    <div>
      <div
        className="z-1 grid w-full grid-cols-6 md:grid-cols-12 lg:relative lg:mx-12 lg:grid lg:w-full lg:grid-cols-12"
        id="content-block"
      >
        <div
          className="col-span-6 space-y-6 px-6 pb-8 pt-8 text-center md:col-span-8 md:col-start-3 md:px-0 lg:col-span-8 lg:col-start-3 lg:space-y-6 lg:px-0 lg:pb-20 lg:pt-16 lg:text-center xl:col-span-6 xl:col-start-4 xl:pb-16"
          id="content-block-inner"
        >
          <h1 className="text-6 md:text-7 xl:text-8 -mb-4">
            <span className="block">Privacy Notice</span>
          </h1>
          <p className="text-3 md:text-4 col-span-12">
            <span className="block">Last updated: October 17, 2023</span>
            <br />
          </p>
        </div>
      </div>

      <div
        data-testid="copy-block"
        className="copy-block mx-auto max-w-screen-2xl bg-white px-6 text-left"
      >
        <div className="copyblock-body container mx-auto grid grid-cols-12">
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            “Remarkably Human” is one of the core principles Asurion’s culture
            strives towards and is front of mind in the way we handle and
            protect your personal data. With that goal in mind, we are providing
            this Privacy Notice (“Notice”) to describe how Asurion collects,
            uses, discloses, and handles your personal data, and information
            about privacy rights you may have, depending upon where you live.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            A Few Terms
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            When we use “Asurion”, or terms like “we”, “us”, or “our” in this
            Notice, we’re referring to Asurion, LLC and our subsidiaries and
            affiliates.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            When we use terms like “you” and “your,” we’re talking about you as
            a visitor to our websites that refer to this Notice or as a user of
            Asurion products and services.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            When we refer to “personal data,” we mean any information that
            relates to an identified or identifiable person, whether that person
            is identifiable directly or indirectly.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Scope of this Privacy Notice
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            This Notice applies to the personal data we collect when you visit
            this website and visit or use any of our other websites or mobile
            applications that reference this Notice, or when we interact with
            you in other ways in providing and marketing our products and
            services to you. If you are looking for information about how we
            collect personal data from our employees or applicants, please
            review the{' '}
            <a
              className="sc-jRQBWg FechV  cta__link !inline"
              tabIndex={0}
              href="https://www.asurion.com/employee-notice/"
              rel=""
              target="_self"
              data-analytics-id="CopyBlock | Privacy-Notice"
              data-testid="cta"
              aria-label="Asurion Employee Privacy Notice"
              data-aui="link"
            >
              Asurion Employee Privacy Notice
            </a>
            .
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            It is important to understand that this Notice applies only when
            Asurion controls how and why your personal data is being used, such
            as when you visit our websites or use our applications or make
            purchases directly from Asurion.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            When Asurion is providing products and services on behalf of and at
            the direction of one of our corporate customers, those services may
            be provided under those customers’ privacy notices and policies
            instead of, or in some cases in addition to, this Notice. If you
            have questions about uses of your personal data by an Asurion
            corporate customer, they should be directed to the company through
            whom you may have encountered Asurion.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Supplemental Notices
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            In some special cases, Asurion may provide a product or service
            offering that includes a supplemental privacy notice that gives
            additional information about that offering’s use of personal data.
            In that situation, if anything in the supplemental notice conflicts
            with information provided in this Notice, the supplemental notice’s
            information controls. If there are supplements, links to such
            notices may be found at the end of this Notice.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Sources of Personal Data
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We collect personal data about you from a variety of sources. Some
            information comes directly from you (or people/entities acting on
            your behalf) during the course of your interactions with Asurion,
            such as when you visit our websites, use our applications, file a
            claim, create a user account, purchase products and services,
            request information or otherwise communicate with us, interact with
            our support teams or other staff, or participate in Asurion events.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We may also gather information about you from other sources,
            including:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              <b>Public Sources</b>, including information from public records
              and information shared in public forums, including social media;
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Social Media Networks</b>;
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Your Mobile Devices and Other Electronics </b>involved in your
              interactions with Asurion, including the operating systems and
              platforms they use<u>; </u>and
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Other Third-Party Sources</b>, including corporate customers we
              partner with and other partners we engage to help us prevent
              fraud, develop and enhance products, and support our marketing and
              promotion activities.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We may combine information that we receive from these different
            sources and use or disclose it for the purposes described
            below.&nbsp;
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Categories of Personal Data We Collect
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We may collect the following categories of personal data about you,
            depending on your relationship or interactions with Asurion.{' '}
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              <b>Identifiers </b>including contact information like your name,
              physical address, phone number and email address, identifying
              information about your device, such as the International Mobile
              Equipment Identity (IMEI) number, and similar numbers.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Location Data,</b> such as approximate location based upon your
              IP address and more specific longitude and latitude coordinates,
              used to verify your identity and for fraud prevention purposes.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Commercial Information,</b> such as purchase history,
              preferences, and transactional information, and records of
              interactions created in providing services and support to you,
              such as support notes or chat histories.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Financial Information,</b> such as payment card information
              collected by our payment processors on our behalf or, in some
              cases, collected directly from you.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Internet or Other Electronic Network Activity Information,</b>{' '}
              such as IP addresses, unique device identifiers, browsing session
              data, and other similar information.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Device Information</b>, including unique identifiers for
              electronic devices, technical or diagnostic information and other
              data we may need to interact with you or provide service for your
              device. This includes any information you provide to us to access
              your device for servicing. In some cases, photographs of your
              device before and after repair may be necessary for insurance
              claims.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Fraud Prevention Information</b>, including data used to help
              identify and prevent fraud, including fraudulent claims and
              fraudulent or criminal customer impersonation.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Audio and Visual Information </b>such as audio recordings of
              interactions we may have with you and video recordings at our
              stores and other physical sites.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Inferences. </b>We draw inferences from the personal data we
              collect to develop a profile reflecting preferences and
              characteristics relevant to our interactions with you.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Demographic Information,</b> such as information about your
              gender, age, level of education, and/or occupation.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Identification Documentation.</b> Where permitted or required
              by applicable law, we may require information to validate your
              identity, such as a copy of your driver’s license, passport, or
              other personal identification card.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Information From On-Site Services</b>. If Asurion provides
              services to you in your home or another remote location, the
              service provider may be able to view anything visible to visitors
              in your home, which may include personal data.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>Other Information You Choose to Share</b>. Some Asurion
              products or services such as Asurion Photos may include a storage
              component that permits you to save information, files, or media of
              your choosing. Subject to the terms of use of such offerings, the
              nature and volume of personal data you choose to upload (if any)
              is entirely up to you, and Asurion makes no use of any such
              personal data except to provide the service or product that
              includes this component.
            </li>
            <li className="item mb-2 text-base text-black">
              <b>
                Special Note for Users of Asurion Photos’ Facial Detection
                Feature
              </b>
              . Asurion Photos offers a feature that includes technology that
              permits users of the application to organize pictures by
              recognizing faces in the user’s photo collection and allowing the
              user to group photos that contain similar faces. Use of this
              feature may create data considered to be biometric identifiers or
              biometric information under some laws. Your use of this feature is
              optional, and the feature is turned off by default. It is always
              your choice whether to enable or disable the feature. Asurion
              makes no use of any of the information generated by this feature
              except for the purpose of providing the service to you.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion does not knowingly collect personal data of individuals
            under the age of 16.&nbsp;
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Cookies and Other Tracking Technologies
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We use several types of tracking technologies (e.g., cookies, web
            beacons, pixels, and device identifiers) to provide functionality,
            to recognize you across different services and devices including for
            the purposes of advertising and re-engaging you if you have searched
            for, viewed, and/or purchased our products or similar products, and
            to improve your experience. Asurion gives you control over which of
            these cookies, pixels or other technologies (other than those
            essential to the functioning of the website) are used with your
            interactions with us via the Asurion Privacy Preference Center,
            which can be accessed by clicking the “Cookie Preferences” button
            that appears at the bottom of the Asurion.com homepage. Learn more
            about how we use{' '}
            <a
              href="https://www.asurion.com/cookie-notice/"
              className="text-primary underline"
            >
              cookies and tracking technologies here
            </a>
            .
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion.com recognizes the Global Privacy Control
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion.com now recognizes the Global Privacy Control (“GPC”), which
            enables us to automatically honor your cookie preferences as
            expressed through a browser or plug-in that uses the GPC without the
            need for you to make a separate effort to express those preferences.
            You can find out more about the GPC, how it works, and different
            ways you can use the tool at{' '}
            <a
              href="https://globalprivacycontrol.org/"
              className="text-primary underline"
            >
              https://globalprivacycontrol.org/
            </a>
            .
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            How We Use Your Data
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              Providing our products and services to you
            </span>
            . This may include:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Activating, authenticating, and maintaining your account;
            </li>
            <li className="item mb-2 text-base text-black">
              Operating, maintaining, and supporting our offerings to you;
            </li>
            <li className="item mb-2 text-base text-black">
              Processing claims you may make;
            </li>
            <li className="item mb-2 text-base text-black">
              Communicating with you, including offering you updates, notices,
              and invitations to participate in surveys;
            </li>
            <li className="item mb-2 text-base text-black">
              Tracking service or quality issues; and
            </li>
            <li className="item mb-2 text-base text-black">
              Improving our offerings.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              Supporting our day-to-day business operations
            </span>
            , including:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Maintaining internal business records;
            </li>
            <li className="item mb-2 text-base text-black">
              Internal reporting;
            </li>
            <li className="item mb-2 text-base text-black">
              Auditing, accounting, and payment collection functions;
            </li>
            <li className="item mb-2 text-base text-black">
              Administration of our systems and networks;
            </li>
            <li className="item mb-2 text-base text-black">
              Research and development;
            </li>
            <li className="item mb-2 text-base text-black">
              Customer relationship management;
            </li>
            <li className="item mb-2 text-base text-black">
              Business planning and other activities associated with managing,
              analyzing improving and developing our business; and
            </li>
            <li className="item mb-2 text-base text-black">
              Corporate transactions, such as mergers and acquisitions.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              Managing legal and operational risks
            </span>
            , including:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Maintaining the security and integrity of Asurion’s systems and
              services;
            </li>
            <li className="item mb-2 text-base text-black">
              Protection of our physical facilities and digital assets;
            </li>
            <li className="item mb-2 text-base text-black">
              Prevention of fraud and other illegal activity;
            </li>
            <li className="item mb-2 text-base text-black">
              Complying with laws; and
            </li>
            <li className="item mb-2 text-base text-black">
              Establishing, exercising or defending against legal claims.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              Marketing our products and services
            </span>
            , including:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Providing you with information about Asurion offerings we think
              may interest you, as permitted by law;
            </li>
            <li className="item mb-2 text-base text-black">
              Facilitating customer-oriented events;
            </li>
            <li className="item mb-2 text-base text-black">
              Evaluating and improving the effectiveness of our marketing
              efforts and strategies;
            </li>
            <li className="item mb-2 text-base text-black">
              Continuous improvement of your experience as an Asurion customer;
              and
            </li>
            <li className="item mb-2 text-base text-black">
              Tailoring advertising that we believe may be of greatest relevance
              to you.
            </li>
          </ul>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            How We Disclose Your Personal Data
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We may disclose your personal data as follows:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              To business partners and service providers to support our business
              purposes, as more fully described in “How We Use Your Data”
            </li>
            <li className="item mb-2 text-base text-black">
              Among Asurion affiliates and subsidiaries, as appropriate for the
              purposes set forth above.
            </li>
            <li className="item mb-2 text-base text-black">
              Where required by law or legal process, to enforce or protect
              Asurion’s rights under the law, or where otherwise appropriate and
              permitted by law to prevent harm, loss, or suspected illegal
              activity.
            </li>
            <li className="item mb-2 text-base text-black">
              In the context of an actual or prospective business transaction
              involving all or part of our company, including mergers,
              acquisitions, consolidations or divestitures.
            </li>
          </ul>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            The categories of personal data we may disclose in any of these
            scenarios will vary depending upon what is necessary in a given
            situation but may include any of the categories of information
            described in “Categories of Personal Data We Collect” above.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            How We Protect Your Personal Data
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We have a robust security program that uses appropriate technical,
            administrative, and physical controls to protect your personal data
            against accidental or unlawful destruction, loss, disclosure,
            alteration or use. We also require this of our vendors and business
            partners.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            How Long We Keep Your Personal Data
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            The amount of time we retain a particular category of personal data
            will vary depending on the purpose for which it was collected, our
            business need for it and our legal obligations with respect to it.
            We retain your personal data for the time needed to fulfill the
            purpose for which that information was collected, to meet any legal
            obligations, and to adhere to our policies on keeping records.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Exercising Your Rights Under Privacy Laws
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Depending upon where you reside, you may have certain rights related
            to your personal data, including rights to access, correct and, in
            some cases, request deletion of your personal data. Requests to
            exercise these rights can be submitted through{' '}
            <a
              href="https://www.asurion.com/privacyrights"
              className="text-primary underline"
            >
              Asurion’s Privacy Rights Portal
            </a>
            , or by calling 1-844-798-7701. Asurion will respond to these
            requests in the manner prescribed by law.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion will take reasonable steps to validate your identity before
            responding to a privacy rights request. The information required
            from you to validate your request will usually be information like
            your name, email address, or other similar contact information. In
            some cases, additional personal data may be needed from you to
            validate your identity. Asurion reserves the right not to respond to
            requests generated through third-party applications or automated
            processes without direct validation of the requests by individuals
            using the resources provided by Asurion for the exercise of these
            rights.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Information for California Residents
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            This portion of the Notice pertains to personal data we collect from
            or about California residents and provides additional information
            required by California law.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              California-Designated Categories of Personal Data We Collect
            </span>
            The categories of data Asurion collects are described above in
            “Categories of Personal Data We Collect.”&nbsp; Although we have
            sought to present a clear and accurate summary of the data we
            collect in that section, California regulations designate specific
            category titles to be used in describing the data.&nbsp; A summary
            of those categories and how they correspond to the information
            previously described appears below.
          </p>
          <div className=" col-span-12 flex flex-col text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full">
                <div className="overflow-hidden">
                  <table className="table-flex text-large min-w-full text-left">
                    <tbody>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>California Designated Categories</b>
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Corresponding Categories Above</b>
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Identifiers
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Identifiers
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Geolocation Data
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Location Data
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Commercial Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Commercial Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Financial Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Financial Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Internet or Other Electronic Network Activity
                            Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Internet or Other Electronic Network Activity
                            Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Audio, Electronic, Visual, Thermal, Olfactory, or
                            Similar Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Audio and Visual Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">Inferences</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">Inferences</span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Characteristics of Protected Classifications under
                            California or Federal Law
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Demographic Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Professional or Employment Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Demographic Information
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Sensitive Personal Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Identification Documentation
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Biometric Information
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Special Note for Users of Asurion Photos’ Facial
                            Detection Feature
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <h3 className="heading bold col-span-12 mb-1 mt-4 text-xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Sensitive Personal Information
          </h3>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Some of the categories listed above include information that
            California designates as “sensitive personal information.”{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We do not use or disclose sensitive personal information except as
            follows:
          </p>
          <ul className="unordered col-span-12 ml-4 list-outside list-disc text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <li className="item mb-2 text-base text-black">
              Where necessary to perform services for or provide products to
              you;
            </li>
            <li className="item mb-2 text-base text-black">
              In detecting security incidents, and protecting against malicious,
              deceptive, fraudulent or illegal activity and responding to such
              activity;
            </li>
            <li className="item mb-2 text-base text-black">
              Short-term transient uses where permitted by law;
            </li>
            <li className="item mb-2 text-base text-black">
              Where needed for services to be provided for us to accomplish
              ordinary business purposes, such as maintaining or servicing
              accounts, providing customer service, processing or fulfilling
              orders and transactions, verifying customer information,
              processing payments, providing financing, providing analytic
              services, providing storage; or
            </li>
            <li className="item mb-2 text-base text-black">
              Where needed to verify or maintain the quality or safety of our
              products and services.
            </li>
          </ul>
          <h3 className="heading bold col-span-12 mb-1 mt-4 text-xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            “Selling” and “Sharing” of Your Personal Data
          </h3>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            California law defines the terms “selling” and “sharing” in a manner
            that differs from what the words typically mean in common
            conversation. Asurion does not sell your personal data for money.
            However, within the last 12 months, we have engaged third parties
            who support our marketing activities and disclosed personal data to
            them in a manner that may be considered a “sale” under California
            law.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Under California law, “sharing” is defined as providing or
            communicating an individual’s personal data to a third party for
            purposes of cross-context behavioral advertising. Asurion has shared
            personal data for these purposes within the last 12 months,
            including as described in “Cookies and Other Tracking Technologies”
            above.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            In the case of both “selling” and “sharing” within the meaning
            described here, the personal data disclosed falls into the
            categories of 1) identifiers, 2) internet or similar network
            activity, and 3) inferences from personal data, and the third
            parties to whom we have disclosed such information assist us in
            providing, maintaining and operating our marketing activities.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion does not sell or share personal data that California defines
            as “sensitive personal information.”
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            As noted above, Asurion does not knowingly collect personal data
            about individuals under the age of 16, and therefore does not sell
            or share such information.
          </p>
          <h3 className="heading bold col-span-12 mb-1 mt-4 text-xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Information About California Privacy Rights
          </h3>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Residents of California have specific and enumerated rights to
            control and understand their personal data. These rights include:
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">Right to Know</span>You may request
            information about what categories of personal data we have about
            you, the categories of sources from which we collected that
            information, the purposes for collecting that personal data, the
            categories of third parties to whom we have disclosed your personal
            data, and the purposes for which the data was disclosed. You may
            also request specific pieces of personal data that we have about
            you.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">Right to Delete</span>You may ask that
            Asurion delete any of the personal data that we possess about you,
            subject to certain exceptions.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">
              Right to Correct Your Personal Data
            </span>
            You may request that Asurion correct any personal data we have about
            you that you believe to be incomplete or inaccurate.
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Requests to exercise these rights can be submitted through{' '}
            <a
              href="https://www.asurion.com/privacyrights"
              className="text-primary underline"
            >
              Asurion’s Privacy Rights Portal
            </a>
            , or by calling 1-844-798-7701. Asurion will respond to these
            requests in the manner prescribed by law, including your right not
            to be discriminated against for exercising these rights.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <span className="font-bold">Opt Out of Sharing</span>Although it has
            done so within the last 12 months as disclosed above, Asurion ceased
            activities that might constitute a &apos;sale&apos; of personal data
            in 2023.
            <span className="font-bold"></span>You may opt out of sharing of
            your personal data by clicking on the “Cookie Preferences” link on
            the button on our homepage to access the Asurion Privacy Preference
            Center and toggling off targeting cookies. As noted above,
            Asurion.com also honors cookie preferences signaled by the Global
            Privacy Control. Please be advised that should you choose to disable
            certain tracking technologies, you may encounter functionality
            issues with some of our sites.
          </p>
          <h3 className="heading bold col-span-12 mb-1 mt-4 text-xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Authorized Agents
          </h3>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            A properly authorized agent may be able to exercise California
            privacy rights on your behalf. Authorized agents must contact us by
            submitting a request through our webform and indicate that they are
            submitting the request as an agent. We reserve the right to require
            the agent to validate their identity and to require the agent to
            demonstrate authority to act on your behalf by providing signed
            permission from you. We may also require you to verify your own
            identity directly with us or to directly confirm with us that you
            provided the authorized agent permission to submit the request.{' '}
          </p>
          <h3 className="heading bold col-span-12 mb-1 mt-4 text-xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Statistics for Asurion’s Responses to California Privacy Rights
            Requests
          </h3>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            California regulations require that Asurion provide statistics
            regarding the number of data subject requests we received,
            fulfilled, and/or denied in the previous calendar year. These are
            presented in the table below.
          </p>
          <div className=" col-span-12 flex flex-col text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full">
                <div className="overflow-hidden">
                  <table className="table-flex text-large min-w-full text-left">
                    <tbody>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Request Type</b>
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Received</b>
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Fulfilled</b>
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Denied</b>
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            <b>Denial Reason(s)</b>
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">Know</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">4</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">1</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">3</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Unable to validate identity
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">Delete</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">89</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">2</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">87</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Legal/Regulatory Requirements;Required to provide
                            service;{' '}
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">Correct</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">3</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">2</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">1</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Unable to validate identity
                          </span>
                        </td>
                      </tr>
                      <tr className="bg-grey border-b border-black first:border-b-0 first:bg-white">
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Opt-Out of Sale or Sharing
                          </span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">61</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">40</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">21</span>
                        </td>
                        <td className="px-2 py-8 text-center text-sm font-light leading-4 first:text-left md:px-4 md:text-base md:leading-5 first:md:pl-8 lg:text-lg">
                          <span className="block min-w-[65px]">
                            Unable to validate identity
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We responded to these requests within a mean time period of 7 days.
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            TRUSTe APEC Certification
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion maintains TRUSTe APEC certifications for the Cross Border
            Privacy Rules (CBPR) system and Privacy Recognition for Processors
            (PRP). You may find information about APEC generally{' '}
            <a
              href="https://cbprs.org/about-cbprs/"
              className="text-primary underline"
            >
              here
            </a>
            .
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            If you have an unresolved privacy or data use concern that we have
            not addressed satisfactorily, please contact our U.S.-based
            third-party dispute resolution provider at{' '}
            <a
              href="https://feedback-form.truste.com/watchdog/request"
              className="text-primary underline"
            >
              TRUSTe
            </a>
            .
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            External Links
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            This Notice does not cover third-party websites or applications that
            may be linked from our services. By linking to third-party services,
            we do not imply an endorsement.{' '}
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            It is a good practice to review the privacy notice of any
            third-party website or application that collects your personal data.{' '}
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Changes to this Notice
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            We update this Notice periodically to comply with global laws and
            regulations and our changing business practices. You will find the
            date of the most recent update at the top of the current Notice.
            Please visit this site periodically to read the Notice. We will
            comply with applicable laws to the extent they require any
            additional means of alerting you to changes to the Notice.{' '}
          </p>
          <h2 className="heading col-span-12 mb-1 mt-16 text-3xl text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Where to Turn with Questions
          </h2>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            You can contact Asurion’s Privacy Office with questions about this
            Notice or the use of personal data related to our websites,
            products, or services, or contact our data protection officer, by
            sending an email to{' '}
            <a
              href="mailto:privacy@asurion.com"
              className="text-primary underline"
            >
              privacy@asurion.com
            </a>{' '}
            or by calling{' '}
            <a href="tel:18447987701" className="text-primary underline">
              1-844-798-7701
            </a>
            , or by mail at:
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Asurion
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            c/o General Counsel
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            cc: Asurion Trust Office
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            140 11th Ave. North
          </p>
          <p className="paragraph col-span-12 mb-2 mt-4 inline-block text-base text-black md:col-span-10 md:col-start-2 xl:col-span-8 xl:col-start-3">
            Nashville, TN 37203
          </p>
        </div>
      </div>
    </div>
  );
}
