import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react';
import { useState } from 'react';
import styled from 'styled-components';

import desktop from './assets/homeplus/desktop.png';
import gameConsole from './assets/homeplus/game-console.png';
import laptop from './assets/homeplus/laptop.png';
import printer from './assets/homeplus/printer.png';
import securityCamera from './assets/homeplus/security-camera.png';
import smartWatch from './assets/homeplus/smart-watch.png';
import speaker from './assets/homeplus/speaker.png';
import tablet from './assets/homeplus/tablet.png';
import tvs from './assets/homeplus/tv.png';

import {
  ContentfulBasicComponent,
  Partner,
} from '@3as-affiliates/shared/types-configs';
import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import Markdown from 'markdown-to-jsx';
import { useParams } from 'react-router-dom';
import { contentfulSpacing, grid } from '../functional-css';
import cooktop from './assets/applianceplus/cooktop.png';
import dishwasher from './assets/applianceplus/dishwasher.png';
import dryer from './assets/applianceplus/dryer.png';
import freezer from './assets/applianceplus/freezer.png';
import microwave from './assets/applianceplus/microwave.png';
import oven from './assets/applianceplus/oven.png';
import refrigerator from './assets/applianceplus/refrigerator.png';
import washer from './assets/applianceplus/washer.png';

const CalculatorContainer = styled.div`
  ${grid};
  gap: 32px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  ${breakpoints.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 115px;
    grid-row-gap: 0px;
  }
`;

const GridColumn = styled.div``;

const Heading = styled(Text)``;
const Copy = styled(Text)`
  white-space: break-spaces;
`;
const Footnote = styled(Text)``;
const TotalContainer = styled.div`
  background-color: #f0f0f5;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  text-align: center;
  span {
    display: block;
    width: 100%;
  }
`;
const TotalLabel = styled(Text)``;
const TotalValue = styled(Text)`
  color: ${colors.primaryDeep};
`;

const OverviewContainer = styled.div``;

const OverviewInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  ${breakpoints.md} {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }

  > div {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 40px;
  grid-row-gap: 20px;

  ${breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
`;
const InputItem = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
const InputImage = styled.img`
  width: 100%;
`;
const InputControlsDecrement = styled.button``;
const InputControlsIncrement = styled.button``;
const InputControlsCount = styled.div``;
const InputControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  ${InputControlsIncrement}, ${InputControlsDecrement}, ${InputControlsCount} {
    border-radius: 100%;
    color: ${colors.primaryDeep};
    border: 0px;
    background-color: #f0f0f5;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${InputControlsCount} {
    width: 40px;
    height: 40px;
    padding-top: 2px;
  }
`;

const InputLabel = styled(Text)`
  padding: 10px;
  opacity: 50%;
  text-align: center;
`;

type TypeEntity = ContentfulBasicComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

export const HomePlusCalculator = styled(HomePlusCalculatorComponent)`
  ${(fields) => contentfulSpacing(fields)};
`;
function HomePlusCalculatorComponent({
  className = '',
  ...fields
}: ComponentProps) {
  const { partner } = useParams();
  const terms =
    partner === Partner.VIU
      ? 'https://www.asurion.com/legal/viu'
      : 'https://partners.affiliates.asurion.com/terms-conditions/home-plus/acrisure';

  const copy = `
Buying individual warranties for tech can add up. It’s more convenient—and cost effective—to cover eligible tech under one protection plan like Asurion Home+®.

What would it cost to replace your tech without coverage? Select your devices to see for yourself, or see [Asurion Home+ Terms and Conditions](${terms}) for complete details.
      `;
  const inputs = [
    { img: tvs, label: `TVs`, value: 480 },
    { img: laptop, label: `Laptop`, value: 594 },
    { img: gameConsole, label: `Game console`, value: 414 },
    { img: tablet, label: `Tablet`, value: 301 },
    { img: desktop, label: `Desktop`, value: 761 },
    { img: securityCamera, label: `Smart security camera`, value: 221 },
    { img: smartWatch, label: `Smart watch`, value: 264 },
    { img: speaker, label: `Wireless speaker`, value: 104 },
    { img: printer, label: `Printer`, value: 138 },
  ];

  return (
    <Calculator
      className={`${className} ${fields?.classes ?? ''}`}
      copy={copy}
      heading="Invest in devices, not warranties"
      totalLabel="Estimated cost to replace your tech*"
      footnote="*Total device value based on 2018 Consumer Technology Ownership Report, published by CTA. Device price estimates based on category medians from nationwide retailer."
      inputs={inputs}
    />
  );
}

export const AppliancePlusCalculator = styled(AppliancePlusCalculatorComponent)`
  ${(fields) => contentfulSpacing(fields)};
`;

export const ApplianceProtectCalculator = styled(
  ApplianceProtectCalculatorComponent
)`
  ${(fields) => contentfulSpacing(fields)};
`;

function ApplianceProtectCalculatorComponent({
  className = '',
  ...fields
}: ComponentProps) {
  const copy = `
The average U.S. household has spent nearly $10,000 on their major appliances.*

Select your home’s appliances to the right to add up your replacement costs if they break down without Asurion Appliance Protect coverage.
  `;
  const inputs = [
    { img: refrigerator, label: `Refrigerator`, value: 1425 },
    { img: washer, label: `Washer`, value: 934 },
    { img: dryer, label: `Dryer`, value: 903 },
    { img: dishwasher, label: `Dishwasher`, value: 876 },
    { img: oven, label: `Oven`, value: 2339 },
    { img: cooktop, label: `Range or Cooktop`, value: 883 },
    { img: freezer, label: `Freezer`, value: 710 },
    { img: microwave, label: `Microwave other than countertop`, value: 394 },
    // { img: ``, label: `Freestanding Ice Maker`, value:  }
  ];

  return (
    <Calculator
      className={className}
      copy={copy}
      heading="Save your budget for what matters"
      totalLabel="YOUR ESTIMATED APPLIANCE INVESTMENT* "
      footnote="* Average consumer investment based on data compiled by HomeAdvisor, 2022"
      inputs={inputs}
    />
  );
}

function AppliancePlusCalculatorComponent({
  className = '',
  ...fields
}: ComponentProps) {
  const copy = `
The average U.S. household has spent nearly $10,000 on their major appliances.*

Select your home’s appliances to the right to add up your replacement costs if they break down without Asurion Appliance+ coverage.
  `;
  const inputs = [
    { img: refrigerator, label: `Refrigerator`, value: 1425 },
    { img: washer, label: `Washer`, value: 934 },
    { img: dryer, label: `Dryer`, value: 903 },
    { img: dishwasher, label: `Dishwasher`, value: 876 },
    { img: oven, label: `Oven`, value: 2339 },
    { img: cooktop, label: `Range or Cooktop`, value: 883 },
    { img: freezer, label: `Freezer`, value: 710 },
    { img: microwave, label: `Microwave other than countertop`, value: 394 },
    // { img: ``, label: `Freestanding Ice Maker`, value:  }
  ];

  return (
    <Calculator
      className={className}
      copy={copy}
      heading="Save your budget for what matters"
      totalLabel="YOUR ESTIMATED APPLIANCE INVESTMENT* "
      footnote="* Average consumer investment based on data compiled by HomeAdvisor, 2022"
      inputs={inputs}
    />
  );
}

type CalculatorInput = {
  img: string;
  label: string;
  value: number;
};

type CalculatorProps = {
  className?: string;
  heading: string;
  copy: string;
  totalLabel: string;
  footnote: string;
  inputs: CalculatorInput[];
};

function DecrementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="2"
      fill="none"
      viewBox="0 0 10 2"
    >
      <path fill="#8223D2" d="M9.8.467H.198v1.067H9.8V.467z"></path>
    </svg>
  );
}

function IncrementSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#8223D2"
        d="M9.8 4.467H5.533V.201H4.466v4.266H.2v1.067h4.266v4.267h1.067V5.534H9.8V4.467z"
      ></path>
    </svg>
  );
}

function Calculator({
  className = '',
  heading,
  copy,
  totalLabel,
  footnote,
  inputs,
}: CalculatorProps) {
  const [units, setUnits] = useState<{ [key: string]: number }>({});

  function handleIncrement(key: string) {
    const newState = { ...units };
    newState[key] = (newState[key] ?? 0) + 1;
    setUnits(newState);
  }

  function handleDecrement(key: string) {
    const newState = { ...units };
    newState[key] = Math.max((newState[key] ?? 0) - 1, 0);
    setUnits(newState);
  }

  const total = inputs
    .map(({ label, value }) => (units[label] ?? 0) * value)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <div className={className}>
      <CalculatorContainer>
        <GridColumn>
          <OverviewContainer>
            <OverviewInnerContainer>
              <Heading forwardedAs={'h2'} weight={'feather'} size={6}>
                {heading}
              </Heading>
              <Copy forwardedAs={'p'} weight={'feather'} size={3}>
                <Markdown>{copy.trim()}</Markdown>
              </Copy>
              <TotalContainer>
                <TotalLabel forwardedAs={'span'} weight={'heavy'} size={1}>
                  {totalLabel}
                </TotalLabel>
                <TotalValue forwardedAs={'span'} weight={'feather'} size={8}>
                  ${total}
                </TotalValue>
              </TotalContainer>
              <Footnote forwardedAs={'p'} size={1}>
                {footnote}
              </Footnote>
            </OverviewInnerContainer>
          </OverviewContainer>
        </GridColumn>
        <GridColumn>
          <InputContainer>
            {inputs.map(({ label, img, value }) => (
              <InputItem key={label}>
                <InputImage alt={label} src={img} />
                <InputControls>
                  <InputControlsDecrement
                    aria-label="Decrement"
                    onClick={() => handleDecrement(label)}
                  >
                    <DecrementSVG />
                  </InputControlsDecrement>
                  <InputControlsCount>{units[label] ?? 0}</InputControlsCount>
                  <InputControlsIncrement
                    aria-label="Increment"
                    onClick={() => handleIncrement(label)}
                  >
                    <IncrementSVG />
                  </InputControlsIncrement>
                </InputControls>
                <InputLabel>{label}</InputLabel>
              </InputItem>
            ))}
          </InputContainer>
        </GridColumn>
      </CalculatorContainer>
    </div>
  );
}
