import { Button, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { useState } from 'react';
import styled from 'styled-components';

const ShareLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const StyledLinkWrapper = styled.div`
  display: flex;
`;

const CopyLinkButton = styled(Button)`
  display: flex;
  top: -5px;
  position: relative;
`;

const StyledTitle = styled.div`
  color: ${colors.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
`;

const StyledLink = styled.a`
  color: ${colors.neutralDeeper};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
`;

interface ShareLinkProps {
  link: string;
  url: string;
  title?: string;
  copyText?: string;
  copiedText?: string;
}

export const ShareLink = ({
  link,
  url,
  title,
  copyText,
  copiedText,
}: ShareLinkProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.error('Error copying to clipboard', err);
      });
  };

  return link === undefined || link.length === 0 ? (
    <div></div>
  ) : (
    <ShareLinkWrapper>
      <StyledTitle>{title || 'YOUR SHARE LINK'}</StyledTitle>
      <StyledLinkWrapper>
        <StyledLink href={url}>{link}</StyledLink>
        <CopyLinkButton
          variant={'flat'}
          size={'small'}
          onClick={handleCopyToClipboard}
        >
          {copied ? copiedText || 'Copied!' : copyText || 'Copy'}
        </CopyLinkButton>
      </StyledLinkWrapper>
    </ShareLinkWrapper>
  );
};
