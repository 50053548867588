import { ContentfulHeroComponent } from '@3as-affiliates/shared/types-configs';
import {
  breakpoints,
  ButtonGroup,
  Link,
  LinkProps,
  Text,
} from '@soluto-private/mx-asurion-ui-react-v3';
import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';
import { contentfulSpacing, grid, halfColumnMD2 } from '../functional-css';
import { ResponsiveImage } from '../responsive-image/ResponsiveImage';

const BaseComponent = styled(Hero)`
  ${(fields) => contentfulSpacing(fields)}
  ${grid};

  ${breakpoints.md2} {
    min-height: 496px;
  }
`;

const Image = styled(ResponsiveImage)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  img {
    width: 100%;
  }
`;

const MediaContainer = styled.div`
  ${halfColumnMD2}
`;

/* Text Sub-component */
const Headline = styled(Text)`
  ${breakpoints.smOnly} {
    font-size: 2.5rem;
  }
`;
const Subheading = styled(Text)`
  p {
    white-space: break-spaces;
    margin: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 1.5rem;
  grid-column: span 6 / span 6;

  ${breakpoints.md} {
    padding: 2rem 0;
    grid-column: span 8 / span 8;
    grid-column-start: 3;
  }

  ${breakpoints.md2} {
    max-width: 35.375rem;
    grid-column: span 6 / span 6;
    padding: 2.5rem 0;
  }
`;

export const HeroVariantTextLeftImageRight = styled(BaseComponent)`
  ${breakpoints.md2} {
    ${TextContainer} {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    ${MediaContainer} {
      grid-column-start: 7;
    }
  }
`;

type TypeEntity = ContentfulHeroComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

function Hero({ className = '', ...fields }: ComponentProps) {
  const hl = fields.headingLevel || 'h1';

  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      <MediaContainer>
        {fields.image && <Image {...fields.image.fields} />}
      </MediaContainer>

      <TextContainer>
        <Headline forwardedAs={hl} size={7} weight={'feather'}>
          {fields.headline}
        </Headline>
        <Subheading forwardedAs={'span'} size={4} weight={'feather'}>
          <Markdown>{fields.subheading}</Markdown>
        </Subheading>

        {fields.cta && fields.cta[0] && (
          <ButtonGroup>
            <Link
              href={fields.cta[0].fields.url}
              variant={'button'}
              btnVariantProps={
                { btnSize: 'large' } as LinkProps['btnVariantProps']
              }
            >
              {fields.cta[0].fields.text}
            </Link>
          </ButtonGroup>
        )}
      </TextContainer>
    </div>
  );
}
