import Player from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import YouTube, { YouTubeEvent, YouTubePlayer } from 'react-youtube';
import styled from 'styled-components';
import GenericPlayButtonImg from './generic-play-button.svg';
import YoutubePlayButtonImg from './youtube-play-button.svg';

export type YoutubePlayerProps = {
  id: string;
  poster?: string;
  autoplay?: boolean;
};

export type VimeoPlayerProps = {
  title?: string;
  vimeoUrl: string;
  poster: string;
  autoplay?: boolean;
};

const Container = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
`;
const IframeContainer = styled.div`
  > div {
    display: flex;
  }
  &,
  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
    float: left;
  }
`;
const ClickablePoster = styled.a`
  display: flex;
  position: relative;

  &:after {
    content: '';
    background-color: transparent;
    background-image: url(${YoutubePlayButtonImg});
    border: none;
    color: #fff;
    cursor: pointer;
    filter: grayscale(100%);
    font: 48px/1.5 sans-serif;
    height: 48px;
    left: 50%;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 0.5em #000;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
    width: 68px;
    z-index: 1;
  }
  &:hover,
  &:focus {
    cursor: pointer;

    &:after {
      filter: none;
    }
  }
`;
const Poster = styled.img`
  width: 100%;
`;

const VimeoClickablePoster = styled(ClickablePoster)`
  position: absolute;

  &:after {
    background-image: url(${GenericPlayButtonImg});
  }
`;

export function YoutubePlayer({
  id,
  autoplay = false,
  poster,
}: YoutubePlayerProps) {
  const [shouldRenderYTEmbed, setShouldRenderYTEmbed] = useState(autoplay);
  const [ytPlayerRef, setYTPlayerRef] = useState<YouTubePlayer | null>(null);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (!autoplay && !inView) ytPlayerRef?.pauseVideo();
  }, [ytPlayerRef, inView, autoplay]);

  function handleYTPlayerReadyEvent(e: YouTubeEvent) {
    setYTPlayerRef(e.target);
    e.target.playVideo();
  }

  function handlePosterClick() {
    setShouldRenderYTEmbed(true);
  }

  return (
    <Container ref={ref}>
      {!shouldRenderYTEmbed && (
        <ClickablePoster onClick={handlePosterClick}>
          <Poster
            aria-hidden={true}
            src={
              poster || `https://i.ytimg.com/vi_webp/${id}/maxresdefault.webp`
            }
          />
        </ClickablePoster>
      )}

      {shouldRenderYTEmbed && (
        <IframeContainer>
          <YouTube videoId={id} opts={{}} onReady={handleYTPlayerReadyEvent} />
        </IframeContainer>
      )}
    </Container>
  );
}

export function VimeoPlayer({
  title,
  vimeoUrl,
  autoplay = false,
  poster,
}: VimeoPlayerProps) {
  const [displayPoster, setDisplayPoster] = useState(!autoplay);
  const [player, setPlayer] = useState<Player | null>(null);
  const playerRef = useRef<HTMLIFrameElement>(null);

  let url = new URL(vimeoUrl);
  const [publicId, privateKey] = url.pathname.split('/').slice(1);
  url = new URL(`https://player.vimeo.com/video/${publicId}`);

  const [ref, inView] = useInView();

  const config: { [key: string]: number | string } = {
    h: privateKey,
    airplay: 0,
    autoplay: autoplay ? 1 : 0,
    muted: autoplay ? 1 : 0,
    controls: autoplay ? 0 : 1, // once the user plays the video, enable vimeo controls
    byline: 0,
    chromecast: 0,
    pip: 0,
    portrait: 0,
    title: 0,
    vimeo_logo: 0,
  };

  // Update the embed url w/ config values
  Object.keys(config).forEach((key) =>
    url.searchParams.set(key, String(config[key]))
  );

  useEffect(() => {
    if (playerRef.current) setPlayer(new Player(playerRef.current));
  }, [playerRef]);

  useEffect(() => {
    if (!autoplay && !inView) player?.pause();
  }, [player, inView, autoplay]);

  function handlePosterClick() {
    player?.play();
    setDisplayPoster(false);
  }

  return (
    <Container ref={ref}>
      {displayPoster && (
        <VimeoClickablePoster onClick={handlePosterClick}>
          <Poster aria-hidden={true} src={poster} />
        </VimeoClickablePoster>
      )}

      <IframeContainer>
        <iframe
          title={title}
          ref={playerRef}
          src={url.toString()}
          allow="autoplay; fullscreen"
          frameBorder={0}
          allowFullScreen
        />
      </IframeContainer>
    </Container>
  );
}
