//to-do: Acrisure is decommissioned; these configs and code will be cleaned up soon

/**
 * This file manages the configuration for the affiliates platform.
 * Organization, Campaign, & Cohort defines the partner, program and agent supported by affiliates platform.
 * Id's must be generated by UUID V4 generator and must be named accordingly in DB.
 * Affiliate is the profile of the agent and affiliation is the relation of the agent with the organization, campaign and cohort.
 */

export enum SupportedPartners {
  Acrisure = 'Acrisure',
  Asurion = 'Asurion',
  Comparion = 'Comparion',
  VIU = 'VIU',
}

export enum SupportedProducts {
  HomePlus = 'HomePlus',
  ApplianceProtect = 'ApplianceProtect',
  AppliancePlus = 'AppliancePlus',
}

// slugify-ed Product Name
export const ProductSlugs: { [key: string]: string } = {
  [SupportedProducts.HomePlus]: 'home-plus',
  [SupportedProducts.ApplianceProtect]: 'appliance-protect',
  [SupportedProducts.AppliancePlus]: 'appliance-plus',
};

export const ProductSlugsInvertedKeys = Object.fromEntries(
  Object.entries(ProductSlugs).map((a) => a.reverse())
);

export const OrganizationIds = {
  [SupportedPartners.Acrisure]: 'a1be629e-78e6-4548-ae9f-2740a7a4f188',
  [SupportedPartners.Asurion]: '63509088-8049-4808-88e0-d8bd988201a3',
  [SupportedPartners.Comparion]: '6124990d-ad7f-4021-a0bc-50c982e4aee3',
  [SupportedPartners.VIU]: '7aff0cfa-9821-4f92-8434-528787d23818',
};

export const OrganizationInvertedKeys = Object.fromEntries(
  Object.entries(OrganizationIds).map((a) => a.reverse())
);

export enum CampaignIds {
  AcrisureDefault = '5a6aec8f-a4d5-4508-a631-655625c65f57',
  AsurionFieldExpertOps = 'ebd54bd2-7847-4031-a805-5be1f9ef4ea5',
  ComparionDefault = '71aa300e-5ea2-4f0d-9fc0-ac9cfb013ed0',
  VIUDefault = '6e400678-dff3-4f86-af66-accd73e60996',
}

export enum CohortIds {
  AcrisureDefault = 'cc44d738-de9c-4678-8ffe-0f32a4854ac3',
  AsurionFieldExpertOpsISPs = '2f396c5b-67c2-4a64-9d98-3240457f3a9b',
  AsurionFieldExpertOpsFSMs = '54abf352-8197-46ab-81db-86a72ecf98d9',
  ComparionDefault = 'b816d40b-722d-4827-ab7c-eedd7c8cb33d',
  VIUDefault = 'cbf93ddf-afa2-432b-8e8d-638c721fff42',
}
