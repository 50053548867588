import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';
import {
  CopyRight,
  PrivacyPolicy,
  ProductTermsAndConditions,
  TermsOfUse,
} from './components';

const Container = styled.footer`
  padding: 19px 8px 16px 8px;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  align-items: start;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 19px 0px 19px 0px;
  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
  ${breakpoints.md} {
    justify-content: flex-end;
  }
`;

type Props = PartnerLogoProps & {
  customClickHandler?: (url: string) => void;
  productTermsUrl?: string;
  termsOfUseUrl?: string;
  privacyPolicyUrl?: string;
};

export const Footer = ({
  customClickHandler,
  partner,
  logoHeight,
  productTermsUrl,
  termsOfUseUrl,
  privacyPolicyUrl,
}: Props) => (
  <Container>
    <PartnerLogo partner={partner} logoHeight={logoHeight} />
    <ContentContainer>
      <CopyRight />
      <LinksContainer>
        {productTermsUrl && (
          <>
            <ProductTermsAndConditions
              productTermsUrl={productTermsUrl}
              customClickHandler={customClickHandler}
            />{' '}
            |
          </>
        )}
        <TermsOfUse
          termsOfUseUrl={termsOfUseUrl}
          customClickHandler={customClickHandler}
        />
        |
        <PrivacyPolicy
          privacyPolicyUrl={privacyPolicyUrl}
          customClickHandler={customClickHandler}
        />
      </LinksContainer>
    </ContentContainer>
  </Container>
);
