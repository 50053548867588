import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import { useGetAffiliate } from '@3as-affiliates/shared/web/data-access/api-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { LoadingSpinnerPage } from '@3as-affiliates/shared/web/features';
import {
  AuthenticationState,
  getUserPersonaPartnerConfig,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const RouteWithUserInfo: React.FC = () => {
  const { authState, getAuthUserInfo, logOut } = useAuth();
  const { getAffiliate } = useGetAffiliate();
  const { updateAppInfo } = useAppData();

  const [userInfoFetch, setUserInfoFetch] = useState<
    'IN_PROGRESS' | 'SUCCESS' | 'ERROR' | 'UNAUTHORIZED'
  >('IN_PROGRESS');

  const isPersonaValid = useCallback(async () => {
    const authUserInfo = await getAuthUserInfo();
    return getUserPersonaPartnerConfig(authUserInfo);
  }, [getAuthUserInfo]);

  useEffect(() => {
    isPersonaValid()
      .then(({ partner, persona }) => {
        if (!persona || !partner) {
          throw new Error('NOT_VALID_PERSONA');
        }

        // Set the AppInfo partner if the users persona has a persona for an org we support
        updateAppInfo({ partner });
        return getAffiliate();
      })
      .then((affiliate) => {
        if (affiliate && affiliate.error) {
          throw new Error('NOT_VALID_AFFILIATE');
        }

        setUserInfoFetch('SUCCESS');
      })
      .catch((err) => {
        if (err.message === 'NOT_VALID_PERSONA') {
          setUserInfoFetch('UNAUTHORIZED');
        } else if (err.message === 'NOT_VALID_AFFILIATE') {
          setUserInfoFetch('UNAUTHORIZED');
        } else {
          console.error('Error checking persona', err.message);
          setUserInfoFetch('ERROR');
          logOut();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPersonaValid, logOut]);

  if (authState === AuthenticationState.LoggedOut) {
    return <Navigate to={Routes.LoggedOutLanding} replace={true} />;
  }

  if (
    authState !== AuthenticationState.Uninitialized &&
    userInfoFetch === 'UNAUTHORIZED'
  ) {
    return <Navigate to={Routes.Unauthorized} replace={true} />;
  }

  if (userInfoFetch === 'SUCCESS') {
    return <Outlet />;
  }

  return <LoadingSpinnerPage pageName={PageNames.LoadingPage} />;
};
