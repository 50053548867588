import { PageContainer } from '@3as-affiliates/shared/web/components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

// Important Style Import
import 'modern-normalize/modern-normalize.css';

export const EnrollmentPageStyles = styled.div<{
  $css?: FlattenSimpleInterpolation;
}>`
  ${({ $css }) => $css};

  header > div:first-child img {
    ${breakpoints.smOnly} {
      height: auto !important;
    }
  }

  footer {
    margin-top: 3rem;
    border-top: 1px solid #d5d6da;
  }

  ${PageContainer} {
    padding-bottom: 0;
  }
`;
