import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { css } from 'styled-components';

export const viuHomePlusCSS = css`
  h1 {
    line-height: 52.8px;
  }

  h2 {
    line-height: 46px;
  }

  h1,
  h2,
  strong {
    font-weight: 700;
  }

  a:not([class]) {
    color: ${colors.primaryDeep};
  }

  .bg-linear-gradient-grey {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.03) 0%,
      rgba(255, 255, 255, 0) 44.51%
    );
  }

  .bg-grey {
    background: #f0f0f5;
  }

  .hero {
    border-top: 1px solid #f8f8fa;

    a,
    a:hover,
    a:focus {
      border-color: ${colors.black};
      background: ${colors.black};
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      min-width: 243px;
    }
  }

  .reviews {
    p img {
      margin: 4px 0px;
    }
  }

  .trust-reviews {
    > div {
      max-width: 300px;
    }
  }
`;
