import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeUtilityComponentVerticalSpacingFields {
  name?: EntryFieldTypes.Symbol;
  defaultPx: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
  mdPx?: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
  md2Px?: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
  lgPx?: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
  xlPx?: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
  xxlPx?: EntryFieldTypes.Integer<
    | 100
    | 12
    | 16
    | 20
    | 24
    | 28
    | 32
    | 36
    | 4
    | 40
    | 44
    | 48
    | 52
    | 56
    | 60
    | 64
    | 68
    | 72
    | 76
    | 8
    | 80
    | 84
    | 88
    | 92
    | 96
  >;
}

export type TypeUtilityComponentVerticalSpacingSkeleton = EntrySkeletonType<
  TypeUtilityComponentVerticalSpacingFields,
  'utilityComponentVerticalSpacing'
>;
export type TypeUtilityComponentVerticalSpacing<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeUtilityComponentVerticalSpacingSkeleton, Modifiers, Locales>;

export function isTypeUtilityComponentVerticalSpacing<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeUtilityComponentVerticalSpacing<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'utilityComponentVerticalSpacing';
}
