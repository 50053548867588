import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const ProductTermsAnchor = styled.div`
  color: ${colors.black};
  margin-right: 4px;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
`;

type Props = {
  customClickHandler?: (url: string) => void;
  productTermsUrl?: string;
};

export const ProductTermsAndConditions = ({
  customClickHandler,
  productTermsUrl,
}: Props) => {
  const handleClick = () => {
    if (customClickHandler && productTermsUrl) {
      customClickHandler(productTermsUrl);
      return;
    }

    window.open(productTermsUrl, '_blank');
  };

  return (
    <ProductTermsAnchor
      data-testid="footer-product-terms-link"
      aria-describedby="new-window-warning"
      onClick={handleClick}
      role="link"
    >
      Terms and Conditions
    </ProductTermsAnchor>
  );
};
