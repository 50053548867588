import { colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { css } from 'styled-components';

export const acrisureAppliancePlusCSS = css`
  .purple {
    background-color: ${colors.primaryDeep};
    color: ${colors.white};
    a {
      color: ${colors.white};
    }

    a[data-aui='link'] {
      border-color: ${colors.white};
      background-color: ${colors.white};
      color: ${colors.black};
      &:hover,
      &:focus {
        color: ${colors.black};
      }
    }
  }

  .monochrome {
    a {
      background-color: ${colors.black};
      border-color: ${colors.black};
    }
  }

  .grey-gradient-start {
    background: rgba(0, 0, 0, 0.03);
  }

  .grey-gradient {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.03) 0%,
      rgba(255, 255, 255, 0) 44.51%
    );
  }

  .dark {
    background: ${colors.neutralDeepest};
    color: ${colors.white};

    a {
      border-color: ${colors.white};
      color: ${colors.white};
      &:hover,
      &:focus {
        color: ${colors.black};
      }
    }
  }
`;
