export enum PageNames {
  HomePage = 'homePage',
  AuthRedirectPage = 'authRedirectPage',
  LandingPage = 'landingPage',
  LoadingPage = 'loadingPage',
  DeclinePage = 'declinePage',
  ProvideFeedbackPage = 'provideFeedbackPage',

  // Terms and Conditions
  TermsConditions = 'termsConditions',

  // Terms and Conditions - Acrisure
  TermsConditions_HomePlus_Acrisure = 'termsConditions_HomePlus_Acrisure',
  TermsConditions_AppliancePlus_Acrisure = 'termsConditions_AppliancePlus_Acrisure',

  // Terms and Conditions - Comparion
  TermsConditions_HomePlus_Comparion = 'termsConditions_HomePlus_Comparion',
  TermsConditions_ApplianceProtect_Comparion = 'termsConditions_AppProtect_Comparion',

  // Terms and Conditions - RealPage
  TermsConditions_RenterTechProtect_Realpage = 'TermsConditions_RenterTechProtect_Realpage',

  PrivacyNotice = 'privacyNotice',
  NotFound = '404PageNotFound',
  Unauthorized = 'unauthorized',
  Enrollment = 'enrollment',
  EnrollmentAppliancePlusAsurion = 'enrollmentAppliancePlusAsurion',
  EnrollmentHomePlusComparion = 'enrollmentHomePlusComparion',
  EnrollmentApplianceProtectComparion = 'enrollmentApplianceProtectComparion',

  EnrollmentHomePlusAcrisure = 'enrollmentHomePlusAcrisure',
  EnrollmentAppliancePlusAcrisure = 'enrollmentAppliancePlusAcrisure',

  EnrollmentHomePlusVIU = 'enrollmentHomePlusVIU',
  EnrollmentApplianceProtectVIU = 'enrollmentApplianceProtectVIU',
}
