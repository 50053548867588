import { ContentfulLogosComponent } from '@3as-affiliates/shared/types-configs';
import { breakpoints, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { contentfulSpacing, grid } from '../functional-css';
import { ResponsiveImage } from '../responsive-image/ResponsiveImage';

export const LogosLarge = styled(LogosLargeComponent)`
  ${(fields) => contentfulSpacing(fields)}
  ${grid};
  grid-row-gap: 5rem;

  ${breakpoints.md2} {
    &:before,
    &:after {
      content: '';
      grid-row-start: 1;
      grid-column-end: span 2;
      grid-row-end: span ${(props) => Math.ceil(props.images.length / 4)};
    }
    &:before {
      grid-column-start: 1;
    }
    &:after {
      grid-column-start: -3;
    }
  }
`;
const Item = styled.div`
  grid-column: span 3 / span 3;
  text-align: center;

  ${breakpoints.md2} {
    grid-column: span 2 / span 2;
  }
`;
const Image = styled(ResponsiveImage)`
  display: block;
  img {
    max-width: 100%;
  }
`;

type TypeEntity = ContentfulLogosComponent;
type TypeFields = TypeEntity['fields'];
type ComponentProps = { className?: string } & TypeFields;

function LogosLargeComponent({ className = '', ...fields }: ComponentProps) {
  return (
    <div className={`${className} ${fields?.classes ?? ''}`}>
      {fields.images.map((image) => {
        if (!image) return null;
        return (
          <Item key={image.fields.label}>
            <Image {...image.fields} />
            <Text>{image.fields.label}</Text>
          </Item>
        );
      })}
    </div>
  );
}
