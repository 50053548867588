import {
  ContentfulBasicComponent,
  ContentfulFeatureComponent,
  ContentfulHeroComponent,
  ContentfulLogosComponent,
  ContentfulMarketingPage,
  ContentfulVerticalSpacingComponent,
  isTypeMarketingComponentBasicComponents,
  isTypeMarketingComponentFeature,
  isTypeMarketingComponentHero,
  isTypeMarketingComponentLogos,
  isTypeUtilityComponentVerticalSpacing,
} from '@3as-affiliates/shared/types-configs';
import {
  AppliancePlusCalculator,
  ApplianceProtectCalculator,
  HomePlusCalculator,
} from '../calculator/Calculator';
import {
  FeatureVariantVimeoLeftTextRight,
  FeatureVariantYoutubeLeftTextRight,
  HeroVariantTextLeftImageRight,
  ImageTextStacked,
  ImageTextStackedFlexCenter,
  ImageTextStackedFullReview,
  ImageTextStackedGrey,
  ImageTextStackedHorizontal,
  Intro,
  LogosLarge,
  LogosRatings,
  VerticalSpacing,
} from '../index';
import { LogosLargeFlexCenter } from '../logos/LogosLargeFlexCenter';

export function ContentfulComponentRenderer(data: ContentfulMarketingPage) {
  if (!data || !data.fields.sections) return null;

  return (
    <>
      {data.fields.sections.map((entry, index) => {
        if (!entry) return null;
        const key = entry.sys.id + index;

        if (isTypeMarketingComponentBasicComponents(entry))
          return <Basic key={key} {...entry.fields} />;
        else if (isTypeMarketingComponentHero(entry))
          return <Hero key={key} {...entry.fields} />;
        else if (isTypeMarketingComponentFeature(entry))
          return <Feature key={key} {...entry.fields} />;
        else if (isTypeMarketingComponentLogos(entry))
          return <Logos key={key} {...entry.fields} />;
        else if (isTypeUtilityComponentVerticalSpacing(entry))
          return <UtilityVerticalSpacing key={key} {...entry.fields} />;
        else return null;
      })}
    </>
  );
}

function UtilityVerticalSpacing(
  fields: ContentfulVerticalSpacingComponent['fields']
) {
  return <VerticalSpacing {...fields} />;
}

function Basic(fields: ContentfulBasicComponent['fields']) {
  if (fields.component === 'ImageTextStacked') {
    if (fields.variant === 'ImageTextStackedGrey')
      return <ImageTextStackedGrey {...fields} />;
    else if (fields.variant === 'ImageTextStackedFlexCenter')
      return <ImageTextStackedFlexCenter {...fields} />;
    else if (fields.variant === 'ImageTextStackedHorizontal')
      return <ImageTextStackedHorizontal {...fields} />;
    else if (fields.variant === 'ImageTextStackedFullReview')
      return <ImageTextStackedFullReview {...fields} />;
    else return <ImageTextStacked {...fields} />;
  } else if (fields.component === 'Intro') {
    return <Intro {...fields} />;
  } else if (fields.component === 'HomePlusCalculator')
    return <HomePlusCalculator {...fields} />;
  else if (fields.component === 'AppliancePlusCalculator')
    return <AppliancePlusCalculator {...fields} />;
  else if (fields.component === 'ApplianceProtectCalculator')
    return <ApplianceProtectCalculator {...fields} />;

  return null;
}

function Hero(fields: ContentfulHeroComponent['fields']) {
  if (fields.variant === 'HeroVariantTextLeftImageRight') {
    return <HeroVariantTextLeftImageRight {...fields} />;
  }

  return null;
}

function Feature(fields: ContentfulFeatureComponent['fields']) {
  if (fields.variant === 'FeatureVariantYoutubeLeftTextRight') {
    return <FeatureVariantYoutubeLeftTextRight {...fields} />;
  } else if (fields.variant === 'FeatureVariantVimeoLeftTextRight') {
    return <FeatureVariantVimeoLeftTextRight {...fields} />;
  }

  return null;
}

function Logos(fields: ContentfulLogosComponent['fields']) {
  if (fields.variant === 'LogosLarge') {
    return <LogosLarge {...fields} />;
  } else if (fields.variant === 'LogosRatings') {
    return <LogosRatings {...fields} />;
  } else if (fields.variant === 'LogosLargeFlexCenter') {
    return <LogosLargeFlexCenter {...fields} />;
  }

  return null;
}
